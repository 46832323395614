import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import LayoutContainer from '../../components/LayoutContainer';
import Box from '../../components/Box';
import Button from '../../components/Button';
import MainContainer from '../../components/MainContainer';
import Logo from '../../components/Logo';
import {destroySession} from '../../reducer';
import Title from '../../components/Title';
import {Helmet} from 'react-helmet';
import useScrollToTop from '../../hooks/useScrollToTop';
import Teaser from '../../components/Teaser';

const PetitionAlreadyCompleted = ({formContext, startNewPetition, history}) => {
  useScrollToTop();
  return <>
    <Helmet>
      <title>{formContext.petitionBrowserTitle}</title>
    </Helmet>
    <MainContainer
      backgroundImageUrl={formContext.backgroundImageUrl}
      backgroundImageTabletUrl={formContext.backgroundImageTabletUrl}
      backgroundImageMobileUrl={formContext.backgroundImageMobileUrl}
    />
    <LayoutContainer
      position={formContext.textPosition}
      header={<div>
        <div style={{float: 'left', display: 'inline-block'}}>
          <Logo url={formContext.websiteUrl} />
        </div>
      </div>}
      content={<><Box>
        <Title title={formContext.step1Header} />
        <div className='widgetContent invalidFlow'>
          <h1>Bedankt voor het ondertekenen van de petitie.</h1>
          <p>Je ondertekening is succesvol verwerkt. Middels onderstaande knop kan je een nieuwe petitie ondertekenen.
          </p>
          <Button label='Onderteken nieuwe petitie' onClick={() => {
            startNewPetition(history);
          }} />
        </div>
      </Box>
        {formContext.allowCallToAction &&
        <div dangerouslySetInnerHTML={{__html: formContext.callToAction}} />}
      </>}
      sidebar={<Teaser header={formContext.pageTeaserHeader} body={formContext.pageTeaserBodyText} />}
      footer={formContext.footer}
    />

  </>;
};

const mapStateToProps = (state) => {
  return {
    formContext: state.reducer.formContext
  };
};

const mapDispatchToProps = (dispatch) => ({
  startNewPetition: (history) => {
    dispatch(destroySession(history));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PetitionAlreadyCompleted));
