import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSmallProductImage = styled.div`
  background-image: url('${props => props.url}');
  height: inherit;
  background-repeat: no-repeat;
  background-size: 100%;
`;

const StyledProductImage = styled.div`
  background-image: url('${props => props.url}');
  height: inherit;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
`;

const StyledContainer = styled.div`
  width: 100%;
  height: 200px;
  margin-bottom: 20px;

  /* Small Smartphone */
  @media (max-width: 480px) {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 90px;
  }

  /* iPad Portrait */
  @media (min-width: 481px) and (max-width: 768px) {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 90px;
  }

  /* iPad Landscape */
  @media (min-width: 769px) and (max-width: 1024px) {
  }
`;

const StyledSmallContainer = styled.div`
  width: 60px;
  height: 80px;
`;

export default class Product extends Component {
  static propTypes = {
    url: PropTypes.string,
    isSmall: PropTypes.bool,
    collapseIfEmpty: PropTypes.bool
  };

  render() {
    if (this.props.collapseIfEmpty && this.props.url.endsWith('/undefined')) {
      return '';
    }

    if(this.props.isSmall) {
      return (
        <StyledSmallContainer>
          <StyledSmallProductImage url={this.props.url} />
        </StyledSmallContainer>
      )
    }

    return (
      <StyledContainer>
        <StyledProductImage url={this.props.url} />
      </StyledContainer>
    );
  }
}
