import _ from 'lodash';

export default function formatLastName (name) {
  if (_.isNil(name)) {
    return name;
  }

  return _.upperFirst(_.split(name, ' ')
    .filter(namePart => _.trim(namePart) !== '')
    .join(' '));
}
