import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

const defaultStyle = css`
  background-image: ${props => `url('${props.backgroundImageUrl}')`};
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  position: fixed;
  top: -10px;
  left: -10px;
  bottom: -10px;
  right: -10px;
  z-index: -10;
  background-size: cover !important;

  @media (min-width: 768px) and (max-width: 1024px) {
    background-image: ${props => `url('${props.backgroundImageTabletUrl}')`};
  }

  @media (max-width: 767px) {
    background-image: ${props => `url('${props.backgroundImageMobileUrl}')`};
  }
`
const StyledMainContainer = styled.div`
  ${props => _.isNil(props.theme.background) ? defaultStyle : props.theme.background};
`;

export default class MainContainer extends Component {
  static propTypes = {
    backgroundImageUrl: PropTypes.string.isRequired,
    backgroundImageTabletUrl: PropTypes.string,
    backgroundImageMobileUrl: PropTypes.string
  };

  render () {
    return (
      <StyledMainContainer
        backgroundImageUrl={this.props.backgroundImageUrl}
        backgroundImageTabletUrl={this.props.backgroundImageTabletUrl}
        backgroundImageMobileUrl={this.props.backgroundImageMobileUrl}
      />
    );
  }
}
