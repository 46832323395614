import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Box = styled.div`
  min-width: 320px;
  position: relative;
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.35);
  background-color: #fff;
  padding: 30px 20px 0 20px;
  height: 600px;
`;

const Content = styled.div`
  overflow-y: scroll;
  overflow: auto;
  max-height: 500px;
  font-size: 18px;
  padding-right: 10px;
  
  h1 {
    font-size: 18px;
    font-family: 'Maax Bold', 'Arial', 'sans-serif';
  }
  
  ::-webkit-scrollbar {
    width: 6px;
  }
  
  ::-webkit-scrollbar-track {
    border: 1px solid #c4c4c4;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    width: 2px;
    border-radius: 3px;
    background-color: #818181;
  }
`;

const CloseBtnContainer = styled.div`
  position:absolute;
  bottom:10px;
  right:0;
`;

const CloseBtn = styled.span`
  margin-top: 33px;
  margin-right: 27px;
  display: inline-block;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  float: right;
`;

export default class ModalDialog extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired
  };

  render () {
    let closeBtn;

    if (this.props.close) {
      closeBtn = (
        <CloseBtnContainer>
          <CloseBtn
            tabIndex='2000'
            onClick={e => {
              e.preventDefault();
              this.props.close();
            }}
          >Sluiten</CloseBtn>
        </CloseBtnContainer>
      );
    }

    return (
      <Box>
        <Content>
          {this.props.children}
        </Content>
        {closeBtn}
      </Box>
    );
  }
}
