import React from 'react';
import FieldWrapper from '../FieldWrapper';
import Checkbox from '../Checkbox';
import {Field} from '@nexios/frontend-forms';
import styled from 'styled-components';


const SubtleText = styled.div`
  color: ${props => props.theme?.telemarketingText?.color || props.theme?.inputCheckbox?.label?.color || 'inherit'};
  font-size: ${props => props.theme?.telemarketingText?.fontSize || '14px'};
  line-height: ${props => props.theme?.telemarketingText?.lineHeight || 'inherit'};
`;

function Telemarketing(
  {
    telemarketingOptIn,
    telemarketingInfoText,
    telemarketingCheckBoxText
  }) {
  const infoTxt = telemarketingOptIn === 'text' || telemarketingOptIn === 'button' ?
    <SubtleText>{telemarketingInfoText}</SubtleText> : null;

  return <div className='marginBottom'>
    <Field
      label={telemarketingCheckBoxText}
      name='allowTelemarketing'
      component={FieldWrapper}
      inputComponent={Checkbox}
      hideLabel
      subtle
    />
    <Field
      name='telemarketingInfoText'
      component={() => infoTxt}
    />
  </div>;
}

export default Telemarketing;
