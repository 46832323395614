import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import BigNumber from 'bignumber.js';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
  background-color: white;
  border-radius: 10px;
  justify-items: stretch;
  align-items: stretch;
  box-shadow: rgba(0, 0, 0, 0.35) 2px 2px 4px 0px;
`;

const NameContainer = styled.div`
  padding: 1rem;
  padding-bottom: .25rem;
  display: flex;
  border-top-left-radius: 10px;

  ${p => css`
    background-color: ${p.theme.teaser.backgroundColor};
  `}
`;

const Name = styled.div`
  ${p => css`
    width:75%;
    font-family: ${p.theme.teaser.fontFamily};
    font-size: 22px;
    line-height: 30px;
  `}
`;

const Motivation = styled.div`
  grid-column: 1 /span 2;
  padding: 1rem;
  display: flex;
  border-bottom-left-radius: 10px;
  ${p => css`

    font-size: ${p.theme.teaser.subtitleFontSize}
  `}
`;

const Image = styled.div`
  justify-self: end;
  height: 100px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-top-right-radius: 10px;
  ${p => p.url && css`
    background-image: url('${p.url}');
  `}
`;

const TargetContainer = styled.div`
  grid-column: 1 /span 2;
`;

const ProgressContainer = styled.div`
  margin: 1rem;
  height: 32px;
  border: 1px solid #DCDCD0;
  border-radius: 5px;
  margin-top: 0;
  ${p => css`
    background-color: ${p.theme.teaser.backgroundColor};
  `}
`;

const Progress = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 5px;
  height: 30px;
  white-space: nowrap;
  font-size: x-small;
  font-weight: bold;
  position: relative;
  ${p => css`
    background-color: ${p.theme.primary};
    width: ${p.value}%;
  `}

  ${p => p.value < 25 && css`
    &:after {
      transform: translateX(calc(100% + 10px));
      content: "€ ${p.revenue}";
    }
  `}

  ${p => p.value > 25 && css`
    &:after {
      content: "€ ${p.revenue}";
    }
  `}
`;

const Target = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 1rem;
  padding-bottom: .25rem;
  ${p => css`
    font-size: ${p.theme.teaser.subtitleFontSize}
  `}
`;

function CollectingSideBar({children}) {
  const searchParams = new URLSearchParams(window.location.search);
  const dm_inf = searchParams.get('dm_inf');
  const [dmInfInfo, setDmInfInfo] = useState();

  useEffect(() => {
    if (!dm_inf) {
      setDmInfInfo(false);
      return;
    }

    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`/api/collectors/${dm_inf}`,
      {
        signal
      })
      .then(resp => resp.json())
      .then(status => {
        if (status.status) {
          setDmInfInfo(status.status);
        } else {
          setDmInfInfo(false);
        }
      });


    return () => {
      controller.abort();
    };
  }, [dm_inf]);


  if (dmInfInfo === undefined) {
    return null;
  }

  if (dmInfInfo === false) {
    return children;
  }

  const moneyValue = BigNumber(dmInfInfo.totalRevenue_m || '0').toFormat()
  const targetMoneyValue = BigNumber(dmInfInfo.onlineCollectorTargetRevenue_m || '0').toFormat();

  return <div><Container>
    <NameContainer>
      <Name>{dmInfInfo.onlineCollectorName}</Name>
    </NameContainer>

    <Image url={dmInfInfo.profileImagePublicUrl} />

    <Motivation>
      {dmInfInfo.onlineCollectorMotivation_txt}
    </Motivation>

    <TargetContainer>
      <Target>
        <div>
          Doelstelling
        </div>
        <div>
          € {targetMoneyValue}
        </div>
      </Target>

      <ProgressContainer>
        <Progress value={dmInfInfo.percentageTarget <= 100 ? dmInfInfo.percentageTarget : 100} revenue={moneyValue} />
      </ProgressContainer>
    </TargetContainer>
  </Container>
  </div>;
//dmInfInfo.percentageTarget <= 100 ? dmInfInfo.percentageTarget : 100
}

export default CollectingSideBar;
