import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import faqPlus from '../../static/icons/faq-plus.svg';
import faqMin from '../../static/icons/faq-min.svg';
import styled, {css} from 'styled-components';

const FaqQuestion = styled.div`
  margin-left: 40px;
  cursor: pointer;
`;

const FaqAnswer = styled.div`
  font-size: 18px;
  margin-left: 40px;
  cursor: pointer;
  overflow: hidden;

  transition: max-height 0.25s ease-out;

  p {
    margin: 0 0 1em 0;
  }
  
  ${props => props.isExpanded ? null : css`display: none`} 
`;

const FaqBorder = styled.div`
  margin: 15px 0;
  border-bottom: 1px solid #000;
`;

const FaqIcon = styled.div`
  float: left;
  cursor: pointer;
`;

const FaqComponent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 1.25em;
  padding-bottom: 2em;

  padding-left: 0.5em;
  padding-right: 0.5em;
`;

const FaqContainer = styled.div`
  margin-top: 2.5em;
  background-color: #fff;
`;

class Faq extends Component {
  constructor (props) {
    super(props);

    this.state = {
      expanded: []
    };

    this.collapse = this.collapse.bind(this);
    this.expand = this.expand.bind(this);
  }

  static propTypes = {
    questions: PropTypes.array
  };

  collapse (indx) {
    return () => {
      const newState = {...this.state};

      newState.expanded[indx] = false;

      this.setState(newState);
    };
  }

  expand (indx) {
    return () => {
      const newState = {...this.state};
      newState.expanded[indx] = true;
      this.setState(newState);
    };
  }

  render () {
    if (_.isEmpty(this.props.questions)) {
      return null;
    }

    const faqs = this.props.questions.map((q, i) => {
      const isExpanded = _.get(this.state, ['expanded', i], true);

      return <div key={i} onClick={isExpanded ? this.collapse(i) : this.expand(i)}>
        <FaqIcon>
          <img src={isExpanded ? faqMin : faqPlus} width='22' height='22' alt='Inklappen' />
        </FaqIcon>
        <FaqQuestion>
          <h3>{q.question}</h3>
        </FaqQuestion>
        <FaqAnswer isExpanded={isExpanded} dangerouslySetInnerHTML={{__html: q.answerHtml_txt}} />
        <FaqBorder />
      </div>;
    });

    return <FaqContainer>
      <FaqComponent>
        <h1>
          Veelgestelde vragen
        </h1>
        <FaqBorder />

        {faqs}
      </FaqComponent>
    </FaqContainer>;
  }
}

export default Faq;
