import _ from 'lodash';

export default function formatInfix (name) {
  if (_.isNil(name)) {
    return name;
  }

  return _.split(name, ' ')
    .filter(namePart => _.trim(namePart) !== '')
    .join(' ');
}
