import {createGlobalStyle, css} from 'styled-components';

const globalCss = css`
  body {
    * {
      font-family: ${props => (props.theme.bodyFontFamily)};
      font-size: ${props => (props.theme.bodyFontSize)};


      h1, h2 {
        font-family: ${props => (props.theme.h1FontFamily)};
        font-size: ${props => (props.theme.h1FontSize)};
      }

      h3 {
        font-family: ${props => (props.theme.h3FontFamily)};
      }

      a {
        :hover {
          color: ${props => props.theme.linkHoverColor};
        }
      }
    }
`;

const GlobalStyle = createGlobalStyle`
  ${globalCss}
`;

export default GlobalStyle;
