import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import _ from 'lodash';

const hasErrors = css`
  box-shadow: ${props => props.theme.dropdown.boxShadowError};
`;

const isValid = css`
  box-shadow: ${props => props.theme.dropdown.boxShadowValid};
`;

const Drpdwn = styled.div`
  position: relative;
  cursor: default;
  width: 100%;

  :after {
    content: '\\f107';
    font-family: 'FontAwesome';
    font-size: 30px;
    right: 12px;
    top: 10px;
    position: absolute;
    pointer-events: none;
    user-select: none;
  }

  select {
    appearance: none;
    width: 100%;
    min-width: 80px;
    height: ${props => props.theme.dropdown.height};
    border-radius: ${props => props.theme.dropdown.borderRadius};
    background-color: ${props => props.theme.dropdown.backgroundColor};
    font-size: ${props => props.theme.dropdown.fontSize};
    padding-left: ${props => props.theme.dropdown.paddingLeft};
    border: ${props => props.theme.dropdown.border};
    box-shadow: ${props => props.theme.dropdown.boxShadow};

    ${props => props.hasErrors ? hasErrors : null}
    ${props => props.isValid ? isValid : null}

    &:focus {
      border: ${props => props.theme.dropdown.borderFocus};
      box-shadow: ${props => props.theme.dropdown.boxShadowFocus};
    }

  }
`;

Drpdwn.defaultProps = {
  theme: {
    dropdown: {
      height: '46px',
      borderRadius: '4px',
      backgroundColor: '#fff',
      fontSize: '18px',
      paddingLeft: '13px',
      border: '1px solid #c4c4c4',
      borderFocus: '1px solid transparent',
      boxShadowFocus: '0 0 0 2px #818181'
    }
  }
};

export default class Dropdown extends Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    setValue: PropTypes.func,
    validate: PropTypes.func,
    name: PropTypes.string
  };

  constructor (props) {
    super(props);
    this.onChange = this.onChange.bind(this);

    if (this.props.list && this.props.list.length === 1) {
      this.props.setValue(this.props.list[0].value);
    }

  }

  onChange (e) {
    this.props.setValue(e.target.value);

    if (this.props.validate) {
      this.props.validate();
    }
  }

  render () {
    let placeholder, options;
    if (this.props.placeholder) {
      placeholder = <option value=''>{this.props.placeholder}</option>;
    }

    const items = this.props.list || [];

    options = items.map(option => {
      return (
        <option
          key={option.value}
          value={option.value}>
          {option.label}
        </option>
      );
    });

    const isValid = this.props.hasErrors === false && this.props.hasWarnings === false && this.props.hasInfos === false && !_.isNil(this.props.value) && this.props.value !== '';
    return (
      <Drpdwn hasErrors={this.props.hasErrors} isValid={isValid}>
        <select
          name={this.props.name}
          value={this.props.value}
          onChange={this.onChange}>
          {placeholder}
          {options}
        </select>
      </Drpdwn>
    );
  }
}
