import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import TaxDeductible1 from './views/TaxDeductible1';
import TaxDeductible2 from './views/TaxDeductible2';
import TaxDeductible3 from './views/TaxDeductible3';
import TaxDeductible4 from './views/TaxDeductible4';
import InvalidFlow from './views/InvalidFlow';
import TaxDeductibleAlreadyCompleted from './views/TaxDeductibleAlreadyCompleted';
import Wizard from './Wizard';

export default function TaxDeductibleRoutes () {
  const errorMessage = 'Oeps, er ging iets mis. U kunt met onderstaande knop een nieuwe aanvraag starten.';
  const errorLabel = 'Aanvraag starten';

  return (
    <Switch>
      <Route path='/' exact>
        <Wizard render={({isDonationCompleted}) => (
          isDonationCompleted ? <TaxDeductibleAlreadyCompleted /> : <TaxDeductible1 />
        )} />
      </Route>
      <Route path='/doneren' exact>
        <Wizard render={({isDonationCompleted, isStep1Completed}) => (
          isDonationCompleted
            ? <TaxDeductibleAlreadyCompleted />
            : isStep1Completed ? <TaxDeductible2 /> : <InvalidFlow message={errorMessage} label={errorLabel} />
        )} />
      </Route>
      <Route path='/betaalwijze' exact>
        <Wizard render={({isDonationCompleted, isStep2Completed}) => (
          isDonationCompleted
            ? <TaxDeductibleAlreadyCompleted />
            : isStep2Completed ? <TaxDeductible3 /> : <InvalidFlow message={errorMessage} label={errorLabel} />
        )} />
      </Route>
      <Route path='/bedankt' exact>
        <Wizard render={({isStep3Completed}) => (
          isStep3Completed ? <TaxDeductible4 /> : <TaxDeductibleAlreadyCompleted />
        )} />
      </Route>
      <Redirect to='/' />
    </Switch>
  );
}
