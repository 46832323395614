import React, {Component} from 'react';
import PropTypes from 'prop-types';
import RadioButton from '../RadioButton';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 20px;
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.theme.radio.gap || '1rem'};
`;

export default class RadioButtonContainer extends Component {
  static propTypes = {
    list: PropTypes.array.isRequired
  };

  render () {
    if (this.props.list === undefined) {
      return null;
    }

    const listItems = this.props.list.map((item, indx) => <RadioButton
      name={this.props.name}
      key={`${this.props.name}-${item.value}`}
      hasErrors={this.props.hasErrors}
      isChecked={this.props.value === item.value}
      label={item.label}
      value={item.value}
      validate={this.props.validate}
      disabled={this.props.disabled}
      setValue={this.props.setValue} />);

    return <Container>
      {listItems}
    </Container>;
  }
}
