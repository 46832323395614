import React from 'react';
import styled from 'styled-components';

const P = styled.p`
  font-size: ${props => props.theme?.paragraph?.fontSize || '14px' },
  line-height: ${props => props.theme?.paragraph?.lineHeight || '1.2rem' }
`

export default function Paragraph({children}) {
  return <P style={{fontSize: '14px', lineHeight: '1.2rem'}}>{children}</P>
}
