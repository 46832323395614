import _ from 'lodash';
import {
  getFormData,
  paymentMethodMap,
  idealBankMap,
  getDonationProduct,
  isProductForm,
  getProducts
} from '../helpers';

export function gaStep3 (formContext) {
  return (dispatch, getState) => {
    const state = getState();
    const data = getFormData(state);
    const hasNewsLetter = formContext.allowNewsletterDisplay === 'step3';

    if (isProductForm(formContext)) {
      window.dataLayer.push({
        'event': 'checkout',
        'newsletterSubscription': hasNewsLetter ? !!_.get(data, ['allowNewsletter']) : undefined,
        'ecommerce': {
          'checkout': {
            'actionField': {
              'step': 3,
              'option': data.donateAsOrganisation ? 'organisatie' : 'persoon'
            },
            products: getProducts(formContext, state)
          }
        }
      });
    } else {
      const paymentMethod = paymentMethodMap[data.paymentMethod] || 'machtiging';

      let other;

      if (data.paymentMethod === 'ideal') {
        other = idealBankMap[data.bank];
      }

      if (data.paymentMethod === 'creditCard') {
        other = data.creditCardBank.toLowerCase();
      }

      if (data.paymentMethod === 'directDebit' || _.isNil(data.paymentMethod)) {
        other = data.frequency === 'oneOff' ? 'eenmalig' : 'doorlopend';
      }

      if (data.paymentMethod === 'payPal') {
        other = 'paypal';
      }

      if (data.paymentMethod === 'crypto') {
        other = 'bitcoin';
      }

      const stepNumber = formContext.donationPageType === '12_donationAnon' ? 2 : 3;

      window.dataLayer.push({
        'ecommerce': {
          'checkout': {
            'actionField': {
              'step': stepNumber,
              'option': `${paymentMethod}|${other}`,
              'id': data.referenceKey
            },
            'products': getDonationProduct(formContext, state)
          }
        },
        'event': 'ecomCheckout',
        'newsletterSubscription': hasNewsLetter ? !!_.get(data, ['allowNewsletter']) : undefined
      });
    }
  };
}
