import React from 'react';
import _ from 'lodash';
import styled, {withTheme} from 'styled-components';
import parse, {domToReact} from 'html-react-parser';
import replaceNewLinesForHtml from '../../utils/replaceNewLines';

function isHtml(value) {
  if (_.isNil(value)) {
    return false;
  }

  return _.trim(value).startsWith('<p>');
}

const StyledTeaser = styled.div`
  ${(props) => _.get(props, ['theme', 'teaser', 'display']) === 'block' && `
    min-width: 300px;
    width: 100%;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 4px 0px;
    border-radius: 10px;
  `}

  ${(props) => _.get(props, ['theme', 'teaser', 'display']) === 'custom' && props.theme.teaser.custom}

  > * > p {
    margin: 0;
    padding: 15px 0;
  }
`;

StyledTeaser.defaultProps = {
  theme: {
    teaser: {
      display: 'inline',
      fontFamily: '"Maax Bold", "Arial", "sans-serif"',
      fontWeight: 'normal',
      fontSize: '41px',
      lineHeight: '51px',
      fontSizeTablet: '54px',
      lineHeightTablet: '65px',
      fontSizeWide: '54px',
      lineHeightWide: '54px',
      subtitleFontFamily: '"Maax Bold", "Arial", "sans-serif"',
      subtitleFontSize: '19px',
      subtitleLineHeight: '24px',
      subtitleFontSizeWide: '20px',
      subtitleLineHeightWide: '25px',
      subtitleFontSizeTablet: '20px',
      subtitleLineHeightTablet: '25px',
      backgroundColor: 'rgba(26,26,26,0.85)',
      backgroundColorSubtitle: 'rgba(26,26,26,0.85)',
      color: '#fff',
      colorSubtitle: '#fff',
      padding: '0 17px 0 17px',
      paddingTopSubtitle: '5px',
      paddingTop: '10px'
    }
  }
};

const StyledTeaserHeader = styled.div`
  background-color: ${props => props.theme.teaser.display === 'inline' ? 'transparent' : props.theme.teaser.backgroundColor};
  color: ${props => props.theme.teaser.color};
  padding: ${props => props.theme.teaser.display !== 'inline' ? props.theme.teaser.padding : '0'};

  ${(props) => _.get(props, ['theme', 'teaser', 'display']) === 'block' && `
    height: ${props.theme.title.height};
    display: flex;
    align-items: center;
    border-radius: 10px 10px 0 0;
  `} @media(min-width: 1025 px) {
  font-size: ${props => props.theme.teaser.fontSizeWide};
  line-height: ${props => props.theme.teaser.lineHeightWide};
}

  @media (min-width: 720px) and (max-width: 768px) {
    font-size: ${props => props.theme.teaser.fontSizeTablet};
    line-height: ${props => props.theme.teaser.lineHeightTablet};
  }
`;

StyledTeaserHeader.defaultProps = StyledTeaser.defaultProps;

const StyledTeaserBody = styled.div`
  background-color: ${props => props.theme.teaser.display === 'inline' ? 'transparent' : props.theme.teaser.backgroundColorSubtitle};
  color: ${props => props.theme.teaser.colorSubtitle};
  padding: ${props => props.theme.teaser.display === 'block' ? props.theme.teaser.padding : '0'};

  ${(props) => _.get(props, ['theme', 'teaser', 'display']) === 'block' && `
    ${props.header ? 'border-radius: 0 0 10px 10px;' : 'border-radius: 10px;'}
  `}

  li::marker {
    color: ${props => props.theme.teaser.display !== 'inline' ? 'inherit' : props.theme.teaser.backgroundColor};
    font-size: ${props => props.theme.teaser.display !== 'inline' ? 'inherit' : '24px'};
  }
`;

StyledTeaserBody.defaultProps = StyledTeaser.defaultProps;

const HighlightHeaderText = styled.span`
  box-decoration-break: clone;
  padding: ${props => props.theme.teaser.display === 'inline' ? props.theme.teaser.padding : '0'};
  background-color: ${props => props.theme.teaser.display !== 'inline' ? 'transparent' : props.theme.teaser.backgroundColor};
  color: ${props => props.theme.teaser.color};
  font-family: ${props => props.theme.teaser.fontFamily};
  font-size: ${props => props.theme.teaser.fontSize};
  font-weight: ${props => props.theme.teaser.fontWeight};
  line-height: ${props => props.theme.teaser.lineHeight};

  @media (min-width: 1025px) {
    font-size: ${props => props.theme.teaser.fontSizeWide};
    line-height: ${props => props.theme.teaser.lineHeightWide};
  }

  @media (min-width: 720px) and (max-width: 768px) {
    font-size: ${props => props.theme.teaser.fontSizeTablet};
    line-height: ${props => props.theme.teaser.lineHeightTablet};
  }
`;

HighlightHeaderText.defaultProps = StyledTeaser.defaultProps;

const HighlightBodyText = styled.span`
  box-decoration-break: clone;
  padding: ${props => props.theme.teaser.display === 'inline' ? props.theme.teaser.padding : '0'};
  background-color: ${props => props.theme.teaser.display !== 'inline' ? 'transparent' : props.theme.teaser.backgroundColorSubtitle};
  color: ${props => props.theme.teaser.colorSubtitle};
  line-height: ${props => props.theme.teaser.subtitleLineHeight};
  font-size: ${props => props.theme.teaser.subtitleFontSize};
  font-family: ${props => props.theme.teaser.subtitleFontFamily};

  * {
    line-height: ${props => props.theme.teaser.subtitleLineHeight};
    font-size: ${props => props.theme.teaser.subtitleFontSize};
    font-family: ${props => props.theme.teaser.subtitleFontFamily};
  }

  @media (min-width: 1025px) {
    font-size: ${props => props.theme.teaser.subtitleFontSizeWide};
    line-height: ${props => props.theme.teaser.subtitleLineHeightWide};

    * {
      font-size: ${props => props.theme.teaser.subtitleFontSizeWide};
      line-height: ${props => props.theme.teaser.subtitleLineHeightWide};
    }
  }

  @media (min-width: 720px) and (max-width: 768px) {
    font-size: ${props => props.theme.teaser.subtitleFontSizeTablet};
    line-height: ${props => props.theme.teaser.subtitleLineHeightTablet};

    * {
      font-size: ${props => props.theme.teaser.subtitleFontSizeTablet};
      line-height: ${props => props.theme.teaser.subtitleLineHeightTablet};
    }
  }
`;

HighlightBodyText.defaultProps = StyledTeaser.defaultProps;

function Teaser({header, body, theme}) {
  const options = {
    replace: (props) => {
      const firstChildData = _.get(props, ['children', 0, 'data']);
      const tagName = _.get(props, ['name']);

      if (tagName === 'p' && !(props.children.length === 1 && _.isString(firstChildData) && firstChildData.charCodeAt(0) === 160)) {
        return <p><HighlightBodyText>{domToReact(props.children)}</HighlightBodyText></p>;
      }

      if (tagName === 'li' && theme.teaser.display === 'inline') {
        return <li><HighlightBodyText>{domToReact(props.children)}</HighlightBodyText></li>;
      }

      if (tagName === 'h1') {
        return <h1><HighlightBodyText>{domToReact(props.children)}</HighlightBodyText></h1>;
      }
      if (tagName === 'h2') {
        return <h2><HighlightBodyText>{domToReact(props.children)}</HighlightBodyText></h2>;
      }
      if (tagName === 'h3') {
        return <h3><HighlightBodyText>{domToReact(props.children)}</HighlightBodyText></h3>;
      }
      if (tagName === 'h4') {
        return <h4><HighlightBodyText>{domToReact(props.children)}</HighlightBodyText></h4>;
      }
      if (tagName === 'h5') {
        return <h5><HighlightBodyText>{domToReact(props.children)}</HighlightBodyText></h5>;
      }
    }
  };

  const bodyHtml = isHtml(body) ? body : replaceNewLinesForHtml(body);
  const parsedBody = parse(bodyHtml, options);

  return (
    <StyledTeaser>
      {!_.isNil(header) && <StyledTeaserHeader>
        <HighlightHeaderText>{header}</HighlightHeaderText>
      </StyledTeaserHeader>}
      {!_.isNil(body) && <StyledTeaserBody>{parsedBody}</StyledTeaserBody>}
    </StyledTeaser>
  );
}

export default withTheme(Teaser);
