import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import LayoutContainer from '../../components/LayoutContainer';
import Box from '../../components/Box';
import MainContainer from '../../components/MainContainer';
import Logo from '../../components/Logo';
import DisplayIban from '../../components/DisplayIban';
import SocialShareButton from '../../components/SocialShareButton';
import OptIn from '../../components/OptIn';
import _ from 'lodash';
import {getFormData} from '@nexios/frontend-forms';
import {gaStep4} from '../../googleAnalytics/step4';
import {sendRevenue} from '../../vwo/sendRevenue';
import {connect} from 'react-redux';
import parseText from '../../utils/parseText';
import {subscribeToNewsletter, smsOptIn} from '../../reducer';
import {Helmet} from 'react-helmet';
import useScrollToTop from '../../hooks/useScrollToTop';
import Input from '../../components/Input';
import Button from '../../components/Button';
import styled from 'styled-components';
import Validation from '../../components/Validation';
import formatPhone from '@nexios/phone-number-formatter';

const Label = styled.div`
  text-align: left;
  font-family: 'Maax Regular';
  font-size: 16px;
  color: #fff;
`;

const Step4 = ({formContext, formData, gaStep4, sendRevenueToVwo, isEmailOptInDone, isSmsOptInDone, saveEmailOptIn, saveSmsOptIn, theme, isProductForm}) => {
  useScrollToTop();

  const [socialMediaTitle, setSocialMediaTitle] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [whatsappUrl, setWhatsappUrl] = useState('');
  const [twitterUrl, setTwitterUrl] = useState('');
  const [thankYouTitle, setThankYouTitle] = useState('');
  const [thankYouText, setThankYouText] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isTelephoneValid, setIsTelephoneValid] = useState(false);
  const [emailValidationMessage, setEmailValidationMessage] = useState('');
  const [telephoneValidationMessage, setTelephoneValidationMessage] = useState('');

  const validateEmail = () => {
    if (_.trim(email) === '') {
      setIsEmailValid(false);
      setEmailValidationMessage('Er is geen e-mailadres ingevuld.');
    } else if (!/^[-0-9a-zA-Z.+_]{1,63}@([-0-9a-zA-Z+_]+\.){1,63}[a-zA-Z]{1,63}$/.test(_.trim(email))) {
      setIsEmailValid(false);
      setEmailValidationMessage('Er is geen geldig e-mailadres ingevuld.');
    } else {
      setIsEmailValid(true);
      setEmailValidationMessage('');
    }
  };

  const validateTelephone = () => {
    if (_.trim(telephone) === '') {
      setIsTelephoneValid(false);
      setTelephoneValidationMessage('Er is geen telefoonnummer ingevuld.');
    } else if (formatPhone(telephone).result !== 'GREEN') {
      setIsTelephoneValid(false);
      setTelephoneValidationMessage('Er is geen geldig telefoonnummer ingevuld.');
    } else {
      setIsTelephoneValid(true);
      setTelephoneValidationMessage('');
    }
  };

  const submitNewsletterOptIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isEmailValid) saveEmailOptIn(formContext.proposition, formContext.pageVariantKey, email);
  };

  const submitSmsOptIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isTelephoneValid) saveSmsOptIn(formContext.proposition, formContext.pageVariantKey, telephone);
  };

  useEffect(() => {
    gaStep4(formContext);

    if (window.isVwoEnabled && !isProductForm) {
      sendRevenueToVwo(formContext);
    }

    const url = encodeURIComponent(document.querySelector('meta[property=\'og:url\']').getAttribute('content'));

    setSocialMediaTitle(encodeURIComponent(formContext.socialMediaTitle));
    setFacebookUrl(`https://www.facebook.com/sharer.php?u=${url}&t=${socialMediaTitle}`);
    setLinkedinUrl(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`);
    setWhatsappUrl(`https://wa.me/?text=${socialMediaTitle}%20${url}`);

    if (formContext.twitterTitle) {
      const twitterTitle = encodeURIComponent(formContext.twitterTitle);
      setTwitterUrl(`https://twitter.com/share?text=${twitterTitle}&url=${url}`);
    } else {
      setTwitterUrl(`https://twitter.com/share?text=${socialMediaTitle}&url=${url}`);
    }

    const isOneOff = formData.frequency === 'oneOff';
    const isDirectDebit = formData.paymentMethod === 'directDebit';

    let titleTemplate;
    let textTemplate;

    if (isOneOff) {
      if (isDirectDebit) {
        titleTemplate = formContext.oneOffThankYouTitle;
        textTemplate = formContext.oneOffThankYouTextHtml_txt;
      } else {
        titleTemplate = formContext.donationThankYouTitle;
        textTemplate = formContext.donationThankYouTextHtml_txt;
      }
    } else if (formContext.mandateType === 'temporary') {
      titleTemplate = formContext.temporaryThankYouTitle;
      textTemplate = formContext.temporaryThankYouTextHtml_txt;
    } else {
      titleTemplate = formContext.continuousThankYouTitle;
      textTemplate = formContext.continuousThankYouTextHtml_txt;
    }

    setThankYouTitle(parseText(formData, titleTemplate));
    setThankYouText(parseText({...formData, ...formContext}, textTemplate, theme));
  }, [gaStep4, formContext, isProductForm, socialMediaTitle, formData, theme, sendRevenueToVwo]);

  const isAnonymous = formContext.donationPageType === '12_donationAnon';
  const allowNewsletter = formContext.allowNewsletter;
  const allowSmsOptIn = formContext.allowSmsOptIn;

  return <>
    <Helmet>
      <title>{formContext.thankYouBrowserTitle}</title>
    </Helmet>
    <MainContainer
      backgroundImageUrl={formContext.backgroundImageUrl}
      backgroundImageTabletUrl={formContext.backgroundImageTabletUrl}
      backgroundImageMobileUrl={formContext.backgroundImageMobileUrl}
    />
    <LayoutContainer
      position={formContext.textPosition}
      header={<div>
        <div style={{float: 'left', display: 'inline-block'}}>
          <Logo url={formContext.websiteUrl} />
        </div>
        <DisplayIban iban='NL08 INGB 0000 0005 55' />
      </div>}
      content={<><Box>
        <div className='widgetContent'>
          <h1>{thankYouTitle}</h1>
          <div className='field'>
            <div dangerouslySetInnerHTML={{__html: thankYouText}} />
          </div>
          {isAnonymous && allowNewsletter && <div className='marginBottom'>
            <OptIn
              label='+ Ja, houd mij op de hoogte'
              isOptInDone={isEmailOptInDone}
            >
              <Label>E-mailadres</Label>
              <Input
                name='email'
                setValue={value => setEmail(value)}
                validate={validateEmail}
                value={email}
                isValid={isEmailValid}
                hasErrors={emailValidationMessage !== ''}
                isRequired={false}
              />
              <Validation errors={[emailValidationMessage]} />
              <Button
                type='button'
                label='Aanmelden nieuwsbrief'
                onClick={submitNewsletterOptIn}
              />
            </OptIn>
          </div>}
          {isAnonymous && allowSmsOptIn && <div className='marginBottom'>
            <OptIn
              label='+ Ja, informeer mij over acties'
              isOptInDone={isSmsOptInDone}
            >
              <Label>Telefoonnummer</Label>
              <Input
                name='telephone'
                setValue={value => setTelephone(value)}
                validate={validateTelephone}
                value={telephone}
                isValid={isTelephoneValid}
                hasErrors={telephoneValidationMessage !== ''}
                isRequired={false}
              />
              <Validation errors={[telephoneValidationMessage]} />
              <Button
                type='button'
                label='Aanmelden sms'
                onClick={submitSmsOptIn}
              />
            </OptIn>
          </div>}
          {formContext.allowSocialShare && <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <SocialShareButton name='whatsapp' url={whatsappUrl} />
            <SocialShareButton name='facebook' url={facebookUrl} />
            <SocialShareButton name='linkedin' url={linkedinUrl} />
            <SocialShareButton name='twitter' url={twitterUrl} />
          </div>}
        </div>
      </Box>
        {formContext.allowCallToAction &&
          <div dangerouslySetInnerHTML={{__html: formContext.callToAction}} />}
      </>}
      footer={formContext.footer}
    />
  </>;
};

const mapStateToProps = (state) => {
  return {
    formContext: state.reducer.formContext,
    formData: getFormData(state, 'donate'),
    isEmailOptInDone: state.reducer.isEmailOptInDone,
    isSmsOptInDone: state.reducer.isSmsOptInDone,
    theme: state.reducer.theme,
    isProductForm: state.reducer.isProductForm
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  gaStep4: (formContext) => {
    dispatch(gaStep4(formContext));
  },
  sendRevenueToVwo: (formContext) => {
    dispatch(sendRevenue(formContext));
  },
  saveEmailOptIn: (campaignPropositionCode, pageVariantKey, email) => {
    dispatch(
      subscribeToNewsletter(
        campaignPropositionCode,
        pageVariantKey,
        email
      )
    );
  },
  saveSmsOptIn: (campaignPropositionCode, pageVariantKey, telephone) => {
    dispatch(
      smsOptIn(
        campaignPropositionCode,
        pageVariantKey,
        telephone
      )
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Step4));

