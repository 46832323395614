import {css} from 'styled-components';
import logoNrk from './icons/nrk/logo-nrk.png';
import progress1 from './icons/nrk/step-indicator-1.svg';
import progress2 from './icons/nrk/step-indicator-2.svg';
import progress3 from './icons/nrk/step-indicator-3.svg';
import whatsapp from './icons/sho/whatsapp.png';
import facebook from './icons/sho/facebook.svg';
import twitter from './icons/sho/x.svg';
import linkedin from './icons/sho/linkedin.svg';
import instagram from './icons/sho/instagram.svg';

const primaryColor = '#199A57';
const primary = '#dc281e';
const errorColor = '#E8141E';

export default {
  // GlobalStyles
  bodyFontFamily: '"Avenir Next", "Arial", "sans-serif"',
  h1FontFamily: '"Avenir Next Demi Bold", "Arial", "sans-serif"',
  h3FontFamily: '"Avenir Next Demi Bold", "Arial", "sans-serif"',
  h1FontSize: '26px',
  bodyFontSize: '18px',
  // AmountButton
  amountButton: {
    color: primaryColor,
    fontFamily: '"Avenir Next Demi Bold", Arial, sans-serif;',
    fontSize: '18px',
    width: 'calc(50% - 6.5px)',
    height: '56px',
    border: '1px solid #C4C4C4',
    borderRadius: '4px',
    backgroundColor: '#fff',
    backgroundColorActive: '#F3FAF6',
    borderHover: `1px solid ${primaryColor}`,
    boxShadowHover: `inset 0px 0px 0px 1px ${primaryColor}`
  },
  buttonContainerSpaceBetween: {
    width: '30%'
  },
  //BackButton
  backButton: {
    fontSize: '12px',
    color: '#4b7382'
  },
  // Box
  boxComponent: {
    borderRadius: '4px'
  },
  // Button
  button: {
    borderRadius: '4px',
    fontFamily: '"Avenir Next Demi Bold", Arial, sans-serif;',
    fontSize: '20px',
    height: '56px',
    backgroundColor: primary,
    color: '#fff',
    boxShadow: 'none',
    borderBottom: '1px #781e1e solid',
    active: css`
      background-color: ${primary};
      border-bottom: none;
      box-shadow: none !important;
    `,
    hover: css`
      background-color: ${primary};
    `,
    focus: css`
      box-shadow: none;
    `
  },
  // Checkbox
  inputCheckbox: {
    border: '1px solid #C4C4C4'
  },
  box: {
    fontSize: '14px',
    colorHasError: errorColor,
    borderHasError: `1px solid ${errorColor}`,
    borderHover: '1px solid #818181',
    backgroundColorChecked: primaryColor,
    backgroundBorderChecked: '1px solid #199A57'
  },
  // DateInput
  dateDropdown: {
    padding: '10px 30px 12.4px 10px',
    border: '1px solid #c4c4c4',
    boxShadowFocus: '0 0 0 3px #c4c4c4',
    borderFocus: '1px solid transparent',
    boxShadowError: '0 0 0 3px #E8141E',
    boxShadowValid: '0 0 0 3px #199A57'
  },
  // DisplayIban
  iban: {
    fontFamily: '"Avenir Next Demi Bold", Arial, sans-serif;',
    color: '#fff'
  },
  // Dropdown
  dropdown: {
    height: '46px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    fontSize: '18px',
    paddingLeft: '13px',
    border: '1px solid transparent',
    boxShadow: '0 0 0 2px #c4c4c4',
    boxShadowFocus: '0 0 0 3px #818181',
    boxShadowError: '0 0 0 3px #E8141E',
    boxShadowValid: '0 0 0 3px #199A57'
  },
  // ErrorMessage
  error: {
    backgroundColor: '#f3f3f3',
    color: '#e8141e',
    height: '49px'
  },
  // Iban
  ibanContainer: {
    display: 'none'
  },
  // Input
  inputComponent: {
    fontSize: '16px',
    border: '1px solid #c4c4c4',
    borderFocus: '1px solid transparent',
    boxShadowFocus: '0 0 0 3px #bbbbbb',
    colorPlaceholder: '#c4c4c4',
    borderValid: 'none',
    boxShadowValid: '0 0 0 3px #078b62',
    backgroundColorValid: '#F3FAF6',
    borderError: 'none',
    boxShadowError: '0 0 0 3px #dc281e',
    colorError: '#E8141E',
    backgroundColorError: '#FFE5E6'
  },
  // TextArea
  textAreaComponent: {
    fontSize: '16px',
    border: '1px solid #c4c4c4',
    borderFocus: '1px solid transparent',
    boxShadowFocus: '0 0 0 3px #bbbbbb',
    colorPlaceholder: '#c4c4c4',
    borderValid: 'none',
    boxShadowValid: '0 0 0 3px #078b62',
    backgroundColorValid: '#F3FAF6',
    borderError: 'none',
    boxShadowError: '0 0 0 3px #dc281e',
    colorError: '#E8141E',
    backgroundColorError: '#FFE5E6'
  },
  // Label
  optionalLabel: {
    color: '#818181'
  },
  label: {
    fontSize: '14px',
    color: '#000',
    colorError: '#E8141E'
  },
  logo: {
    width: '79px',
    height: '40px',
    backgroundImage: logoNrk,
    widthMobile: '79px',
    heightMobile: '40px',
    margin: '30px 0 20px -4px',
    marginMobile: '30px 0 20px 0'
  },
  // PaymentButton
  paymentButton: {
    color: primaryColor,
    fontFamily: '"Avenir Next Demi Bold", Arial, sans-serif;',
    fontSize: '11px',
    width: 'calc(36% - 10px);',
    height: '56px',
    lineHeight: '45px',
    border: '1px solid #C4C4C4',
    borderRadius: '4px',
    backgroundColor: '#fff',
    backgroundColorActive: '#F3FAF6',
    borderHover: `1px solid ${primaryColor}`,
    boxShadowHover: `inset 0px 0px 0px 1px ${primaryColor}`
  },
  // ProgressBar
  progressBar: {
    step1: css`
      width: 70px;
      background: url(${progress1}) no-repeat 0;
    `,
    step2: css`
      width: 70px;
      background: url(${progress2}) no-repeat 0;
    `,
    step3: css`
      width: 70px;
      background: url(${progress3}) no-repeat 0;
    `
  },
  // RadioButton
  inputRadioButton: {
    backgroundColor: '#fff',
    border: '1px solid #c4c4c4',
    backgroundColorDisabled: '#e3e3e3',
    backgroundColorActive: '#199a57',
    borderActive: '1px solid #199a57',
    borderDisabled: '1px solid #e3e3e3'
  },
  radio: {
    borderHover: '1px solid #818181',
    colorHasError: '#e8141e',
    borderHasError: '1px solid #e8141e'
  },
  // TeaserHeader
  teaser: {
    display: 'inline',
    fontFamily: '"Avenir Next", Arial, sans-serif;',
    fontWeight: '600',
    fontSize: '32px',
    lineHeight: '47px',
    backgroundColor: 'rgba(0,0,0,1)',
    backgroundColorSubtitle: 'rgba(255,255,255,1)',
    color: '#fff',
    colorSubtitle: '#000',
    padding: '3px 5px',
    fontSizeTablet: '32px',
    lineHeightTablet: '47px',
    fontSizeWide: '32px',
    lineHeightWide: '47px',
    subtitleFontFamily: '"Avenir Next", Arial, sans-serif;',
    subtitleFontSize: '18px',
    subtitleLineHeight: '31px',
    subtitleFontSizeWide: '18px',
    subtitleLineHeightWide: '31px',
    subtitleFontSizeTablet: '18px',
    subtitleLineHeightTablet: '31px',
    paddingTop: '0',
    paddingTopSubtitle: '0',
    topLeftTextAlignment: 'left',
    topRightTextAlignment: 'right',
    bottomLeftTextAlignment: 'left',
    bottomRightTextAlignment: 'right'
  },
  teaserContainer: {
    margin: '10px 0 0 0'
  },
  // Title
  title: {
    color: '#fff',
    fontFamily: '"Avenir Next", "Arial", "sans-serif"',
    fontSize: '18px',
    height: '60px',
    fontWeight: '500',
    borderRadius: '4px',
    backgroundColor: '#000'
  },
  // Validation
  validation: {
    color: '#e8141e',
    fontSize: '16px'
  },
  // OptIn
  optInButton: {
    fontFamily: '"Avenir Next", "Arial", "sans-serif"',
    fontSize: '20px',
    backgroundColor: '#4b7382',
    color: '#fff',
    boxShadow: 'none',
    active: css`
      background-color: #4b7382;
      border-bottom: none;
      box-shadow: none !important;
    `,
    hover: css`
      background-color: #4b7382;
    `,
    focus: css`
      box-shadow: none;
    `
  },
  // SocialShareButton
  socialShareButton: {
    backgroundSize: '50px',
    color: '#000',
    whatsapp: css`background-image: url(${whatsapp})`,
    facebook: css`background-image: url(${facebook})`,
    twitter: css`background-image: url(${twitter})`,
    linkedin: css`background-image: url(${linkedin})`,
    instagram: css`background-image: url(${instagram})`
  }
};


