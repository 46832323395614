import {getFormData, freqMap} from '../../googleAnalytics/helpers';

export function sendRevenue (formContext) {
  return (dispatch, getState) => {
    const state = getState();
    const data = getFormData(state);
    const amount = data.amount_m || data.customAmount_m;
    const revenue = amount * freqMap[data.frequency].times;

    window._vis_opt_queue = window._vis_opt_queue || [];
    window._vis_opt_queue.push(function () {
      // eslint-disable-next-line no-undef
      _vis_opt_revenue_conversion(`${revenue}`);
    });
  };
}
