import {css} from 'styled-components';
import logoAmf from './icons/amf/logo-armoedefonds.png';
import progress1 from './icons/amf/step-indicator-1.svg';
import progress2 from './icons/amf/step-indicator-2.svg';
import progress3 from './icons/amf/step-indicator-3.svg';
import progress1of2 from './icons/amf/step-indicator-1of2.svg';
import progress2of2 from './icons/amf/step-indicator-2of2.svg';
import whatsapp from './icons/sho/whatsapp.png';
import facebook from './icons/sho/facebook.svg';
import linkedin from './icons/sho/linkedin.svg';
import instagram from './icons/sho/instagram.svg';
import twitter from './icons/sho/x.svg';

// eslint-disable-next-line no-unused-vars
const [PRIMARY, SECONDARY, UI900, UI700, UI500, UI300, WHITE, PRIMARY_HOVER] = [
  '#941F82',
  '#FFE900',
  '#1D1E1B',
  '#575451',
  '#E7DED6',
  '#FDF4EF',
  '#FFFFFF',
  'rgb(169,86,167)'
];

const primaryColor = PRIMARY;
const primary = PRIMARY;
const hover = PRIMARY_HOVER;
const errorColor = '#c02b0a';
const errorBg = '#fff9f9';

let defaultFont = '"DM Sans", sans-serif';

export default {
  // GlobalStyles
  bodyFontFamily: defaultFont,
  h1FontFamily: defaultFont,
  h3FontFamily: defaultFont,
  h1FontSize: '22px',
  bodyFontSize: '15px',
  // AmountButton
  amountButton: {
    color: WHITE,
    fontFamily: defaultFont,
    fontSize: '17px',
    width: '94px',
    height: '78px',
    border: '0',
    borderRadius: '16px',
    backgroundColor: UI500,
    backgroundColorActive: PRIMARY,
    colorActive: WHITE
  },
  buttonContainerSpaceBetween: {
    width: '94px'
  },
  //BackButton
  backButton: {
    fontSize: '15px',
    color: '#1A1A1A'
  },
  // Box
  boxComponent: {
    borderRadius: '16px',
    border: `3px solid ${UI900}`
  },
  // Button
  button: {
    borderRadius: '5px',
    fontFamily: defaultFont,
    fontSize: '20px',
    height: '56px',
    backgroundColor: primary,
    color: '#fff',
    boxShadow: 'none',
    active: css`
      background-color: ${hover};
      border-bottom: none;
      box-shadow: none !important;
    `,
    hover: css`
      background-color: ${hover};
    `,
    focus: css`
      box-shadow: none;
    `
  },
  // Checkbox
  inputCheckbox: {
    border: `2px solid ${UI500}`
  },
  box: {
    fontSize: '15px',
    colorHasError: errorColor,
    borderHasError: `1px solid ${errorColor}`,
    borderHover: '1px solid #818181',
    backgroundColorChecked: primaryColor,
    backgroundBorderChecked: '1px solid #199A57'
  },
  // DateInput
  dateDropdown: {
    padding: '15.6px 30px 12.4px 10px',
    border: `2px solid ${UI500}`,
    borderRadius: '16px',
    boxShadowFocus: '0',
    borderFocus: `2px solid ${UI500}`,
    borderValid: `2px solid ${UI500}`,
    boxShadowError: '0',
    boxShadowValid: '0',
    backgroundColorValid: 'inherit',
    height: 'auto',
    chevronTop: '16px'
  },
  // DisplayIban
  iban: {
    fontFamily: defaultFont,
    color: '#fff'
  },
  // Dropdown
  dropdown: {
    height: '46px',
    borderRadius: '16px',
    backgroundColor: '#fff',
    fontSize: '18px',
    paddingLeft: '13px',
    border: `2px solid ${UI500}`,
    borderFocus: `2px solid ${UI500}`,
    boxShadowFocus: '0'
  },
  // ErrorMessage
  error: {
    backgroundColor: errorBg,
    color: errorColor,
    height: '49px'
  },
  // Iban
  ibanContainer: {
    display: 'none'
  },
  // Input
  inputComponent: {
    fontSize: '16px',
    border: `2px solid ${UI500}`,
    borderFocus: `2px solid ${UI500}`,
    borderRadius: '16px',
    boxShadowFocus: 'none',
    colorPlaceholder: '#c4c4c4',
    borderValid: `2px solid ${UI500}`,
    boxShadowValid: 'none',
    borderError: `1px solid ${errorColor}`,
    boxShadowError: 'none',
    colorError: errorColor,
    backgroundColorError: errorBg
  },
  // TextArea
  textAreaComponent: {
    fontSize: '15px',
    border: '1px solid #c4c4c4',
    borderFocus: '1px solid #818181',
    boxShadowFocus: 'none',
    colorPlaceholder: '#c4c4c4',
    borderValid: '1px solid #199a57',
    boxShadowValid: 'none',
    backgroundColorValid: '#F3FAF6',
    borderError: '1px solid #E8141E',
    boxShadowError: 'none',
    colorError: errorColor,
    backgroundColorError: errorBg
  },
  // Label
  optionalLabel: {
    color: '#818181'
  },
  label: {
    fontSize: '15px',
    color: '#000',
    colorError: errorColor
  },
  logo: {
    width: '140px',
    height: '75px',
    backgroundImage: logoAmf,
    widthMobile: '120px',
    heightMobile: '103px',
    margin: '0',
    marginMobile: '0'
  },
  // PaymentButton
  paymentButton: {
    color: UI900,
    fontFamily: defaultFont,
    fontSize: '16px',
    width: '115px',
    height: '78px',
    lineHeight: '78px',
    border: `2px solid ${UI500}`,
    borderRadius: '16px',
    backgroundColor: '#fff',
    backgroundColorActive: UI500,
    borderHover: `2px solid ${UI500}`,
    boxShadowHover: `0`
  },
  // RadioButton
  inputRadioButton: {
    backgroundColor: '#fff',
    border: '1px solid #c4c4c4',
    backgroundColorActive: PRIMARY,
    borderActive: `1px solid ${PRIMARY}`
  },
  // ProgressBar
  progressBar: {
    step1: css`
      width: 70px;
      background: url(${progress1}) no-repeat 0;
    `,
    step2: css`
      width: 70px;
      background: url(${progress2}) no-repeat 0;
    `,
    step3: css`
      width: 70px;
      background: url(${progress3}) no-repeat 0;
    `,
    step1of2: css`
      width: 40px;
      background: url(${progress1of2}) no-repeat 0;
    `,
    step2of2: css`
      width: 40px;
      background: url(${progress2of2}) no-repeat 0;
    `
  },
  radio: {
    borderHover: `1px solid ${PRIMARY_HOVER}`,
    colorHasError: errorColor,
    borderHasError: `1px solid ${errorColor}`
  },
  // TeaserHeader
  teaser: {
    display: 'custom',
    custom: {
      border: '3px solid #000',
      backgroundColor: UI300,
      padding: '.5rem',
      marginRight: '1rem'
    },
    fontFamily: defaultFont,
    fontWeight: '800',
    fontSize: '32px',
    lineHeight: '36px',
    fontSizeTablet: '32px',
    subtitleFontFamily: defaultFont,
    subtitleFontSize: '16px',

    backgroundColor: 'transparent',
    backgroundColorSubtitle: 'transparent',
    color: UI900,
    colorSubtitle: '#000',
    padding: '0',
    paddingTop: '0',
    paddingTopSubtitle: '0',
    topLeftTextAlignment: 'left',
    topRightTextAlignment: 'right',
    bottomLeftTextAlignment: 'left',
    bottomRightTextAlignment: 'right'
  },
  teaserContainer: {
    margin: '0',
    padding: '0'
  },
  // Title
  title: {
    color: '#fff',
    fontFamily: defaultFont,
    fontSize: '18px',
    height: '65px',
    fontWeight: 'normal',
    borderRadius: '10px',
    backgroundColor: primary
  },
  // Validation
  validation: {
    color: errorColor,
    fontSize: '15px'
  },
  // OptIn
  optInButton: {
    fontFamily: defaultFont,
    fontSize: '20px',
    backgroundColor: '#000',
    color: primary,
    boxShadow: 'none',
    active: css`
      background-color: #000;
      border-bottom: none;
      box-shadow: none !important;
    `,
    hover: css`
      background-color: #000;
    `,
    focus: css`
      box-shadow: none;
    `
  },
  // SocialShareButton
  socialShareButton: {
    backgroundSize: '50px',
    color: '#000',
    whatsapp: css`background-image: url(${whatsapp})`,
    facebook: css`background-image: url(${facebook})`,
    twitter: css`background-image: url(${twitter})`,
    linkedin: css`background-image: url(${linkedin})`,
    instagram: css`background-image: url(${instagram})`
  }
};


