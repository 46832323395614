import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import DonationAlreadyCompleted from '../../views/DonationAlreadyCompleted';
import {gaStep4} from '../../googleAnalytics/step4';

const GoogleAnalyticsWrapper = ({formContext, gaStep4}) => {

  gaStep4(formContext);

  return <DonationAlreadyCompleted />;
};

const mapStateToProps = (state) => {
  return {
    formContext: state.reducer.formContext
  };
};

const mapDispatchToProps = (dispatch) => ({
  gaStep4: (formContext) => {
    dispatch(gaStep4(formContext));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GoogleAnalyticsWrapper));
