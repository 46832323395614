import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import logoSho from '../../themes/icons/sho/logo-sho.svg';

const LogoComponent = styled.div`
  cursor: pointer;
  width: ${props => props.theme.logo.width};
  height: ${props => props.theme.logo.height};
  background-image: url(${props => props.theme.logo.backgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  margin: ${props => props.theme.logo.margin};
  
  @media (max-width: 480px) {
       width: ${props => props.theme.logo.widthMobile};
       height: ${props => props.theme.logo.heightMobile};
       
   }
   
   @media (max-width: 364px) {
      margin: ${props => props.theme.logo.marginMobile};
   }
`;

LogoComponent.defaultProps = {
  theme: {
    logo: {
      width: '128px',
      height: '102px',
      backgroundImage: logoSho,
      widthMobile: '73px',
      heightMobile: '58px',
      margin: '0',
      marginMobile: '0'
    }
  }
};

export default class Logo extends Component {
  static propTypes = {
    url: PropTypes.string
  };

  render () {
    let onClick;
    if (this.props.url) {
      onClick = () => window.open(this.props.url, '_blank');
    }
    return (
      <LogoComponent onClick={onClick} />
    );
  }
}
