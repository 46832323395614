import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  color: ${props => props.theme.validation.color};
  font-size: ${props => props.theme.validation.fontSize};
  margin-top: 5px;
`;

Container.defaultProps = {
  theme: {
    validation: {
      fontSize: '16px',
      color: '#e8141e'
    }
  }
};

export default class Validation extends Component {
  static propTypes = {
    infos: PropTypes.array,
    warnings: PropTypes.array,
    errors: PropTypes.array
  };

  render () {
    const {errors, warnings, infos} = this.props;

    return <Container>
      {errors && errors.map(e => <div key={e}>{e}</div>)}
      {warnings && warnings.map(e => <div key={e}>{e}</div>)}
      {infos && infos.map(e => <div key={e}>{e}</div>)}
    </Container>;
  }
}
