import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './layoutContainer.css';
import styled from 'styled-components';
import {withTheme} from 'styled-components';
import classNames from 'classnames';
import Faq from '../Faq';

const SideBar = styled.div`
  width: 90%;
  grid-area: sidebar;
  margin-top: 5px; // only for topLeft and topRight
  align-self: ${props => _.includes(['topLeft', 'topRight'], props.position) ? 'start' : 'end'};
  text-align: ${props => _.get(props, ['theme', 'teaser', props.position + 'TextAlignment'])};

  @media (min-width: 769px) {
    margin-left: 20px;
  }

  @media (min-width: 481px) and (max-width: 768px) {
    text-align: left;
    width: 100%;
  }

  @media (min-width: 364px) and (max-width: 480px) {
    text-align: left;
    width: 100%;
  }
`;

const Content = styled.div`{
  @media (max-width: 480px) {
    --mobileHeaderSpace: ${_.get(this, ['props', 'theme', 'mobileHeaderSpace'])};
  }
}`;

class LayoutContainer extends Component {
  static propTypes = {
    header: PropTypes.element,
    content: PropTypes.element.isRequired,
    sidebar: PropTypes.element,
    footer: PropTypes.element,
    position: PropTypes.string,
    isCollecting: PropTypes.bool
  };

  static defaultProps = {
    position: 'bottomLeft'
  };

  render() {
    const faq = _.isNil(this.props.faq) || _.size(this.props.faq) === 0
      ? null
      : <>
        <div className='blankLeft' />
        <div className='faq'>
          <Faq questions={this.props.faq} />
        </div>
        <div className='blankRight' />
      </>;

    const footer = <div className='footer'>
      {faq}
      {!_.isNil(this.props.footer) && <div className='row2' dangerouslySetInnerHTML={{__html: this.props.footer}} />}
    </div>;

    const smallHeader = _.has(this, ['props', 'theme', 'smallHeader'])
      ? _.get(this, ['props', 'theme', 'smallHeader'])
      : false;
    const containerClasses = classNames({
      containerLeft: _.includes(['topLeft', 'bottomLeft'], this.props.position),
      container: _.includes(['topRight', 'bottomRight'], this.props.position),
      smallHeader: smallHeader,
      isCollecting: this.props.isCollecting
    });

    let content = <Content className={containerClasses}>
      <div className='header'>{this.props.header}</div>
      <div className='contentBottomLeft'>{this.props.content}</div>
      <SideBar isCollecting={this.props.isCollecting} position={this.props.position}>{this.props.sidebar}</SideBar>
      {footer}
    </Content>;

    if (this.props.position === 'topLeft') {
      content = <Content className={containerClasses}>
        <div className='header'>{this.props.header}</div>
        <div className='contentTopLeft'>{this.props.content}</div>
        <SideBar isCollecting={this.props.isCollecting} position={this.props.position}>{this.props.sidebar}</SideBar>
        {footer}
      </Content>;
    } else if (this.props.position === 'topRight') {
      content = <Content className={containerClasses}>
        <div className='header'>{this.props.header}</div>
        <div className='contentTopRight'>{this.props.content}</div>
        <SideBar isCollecting={this.props.isCollecting} position={this.props.position}>{this.props.sidebar}</SideBar>
        {footer}
      </Content>;
    } else if (this.props.position === 'bottomRight') {
      content = <Content className={containerClasses}>
        <div className='header'>{this.props.header}</div>
        <div className='contentBottomRight'>{this.props.content}</div>
        <SideBar isCollecting={this.props.isCollecting} position={this.props.position}>{this.props.sidebar}</SideBar>
        {footer}
      </Content>;
    }

    return (content);
  }
}

export default withTheme(LayoutContainer);
