import _ from 'lodash';
import parseAndGetBigNumber from '../../utils/parseAndGetBigNumber';

export function is_gtm_loaded() {
  return true;

  // let gtm_started_event = window.dataLayer.find(element => element['gtm.start']);
  //
  // if (!gtm_started_event) {
  //   return false; // Not even the GTM inline config has executed
  // }
  // // else if (!gtm_started_event['gtm.uniqueEventId']) {
  // //   return false; // GTM inline config has ran, but main GTM js is not loaded (likely AdBlock, NoScript, URL blocking etc.)
  // // }
  //
  // return true; // GTM is fully loaded and working
}

export function getFormData(state) {
  const data = {
    referenceKey: state.reducer.referenceKey
  };

  Object
    .keys(state.form)
    .filter(key => key.startsWith('donate_data_'))
    .forEach(key => {
      const attributeName = key.replace('donate_data_', '');
      if (state.form[`donate_meta_${attributeName}_isVisible`] !== false || _.includes(['postalCode', 'houseNumber_i', 'houseNumberAddition', 'street', 'city'], attributeName)) {
        data[attributeName] = state.form[key];
      }
    });

  return data;
}

export const freqMap = {
  oneOff: {
    value: 'eenmalig',
    times: 1
  },
  monthly: {
    value: 'maandelijks',
    times: 12
  },
  quarterly: {
    value: 'kwartaal',
    times: 4
  },
  halfYearly: {
    value: 'halfjaar',
    times: 2
  },
  yearly: {
    value: 'jaar',
    times: 1
  }
};

export const paymentMethodMap = {
  ideal: 'ideal',
  creditCard: 'creditcard',
  payPal: 'paypal',
  directDebit: 'machtiging'
};

export const idealBankMap = {
  ABNANL2A: 'abnamro',
  RABONL2U: 'rabobank',
  INGBNL2A: 'ingbank',
  SNSBNL2A: 'snsbank',
  RBRBNL21: 'regiobank',
  ASNBNL21: 'asnbank',
  BUNQNL2A: 'bunq',
  TRIONL2U: 'triodos',
  FVLBNL22: 'vanlanschot',
  KNABNL2H: 'knab',
  FRBKNL2L: 'frieslandbank'
};

export function getDonationProduct(formContext, state) {
  const calculateAmount = (amount) => {
    if (formContext.mandateType === 'temporary' && data.frequency === 'monthly') {
      return _.multiply(getPeriod(), amount);
    }
    return _.multiply(amount, freqMap[data.frequency].times);
  };

  const getCategorySuffix = () => {
    if (data.frequency === 'oneOff') return 'eenmalig';
    if (formContext.mandateType === 'temporary') return 'tijdelijk';
    return 'doorlopend';
  };

  const getIdPrefix = () => {
    if (data.frequency === 'oneOff') return 'ed';
    if (formContext.mandateType === 'temporary') return 'td';
    return 'dd';
  };

  const getPeriod = () => {
    if (formContext.mandateType === 'temporary' && data.frequency === 'monthly') {
      return _.toInteger(data.period);
    }
    return null;
  };

  const data = getFormData(state);
  const frequencySelected = formContext.frequencies.find(freq => freq.value === data.frequency);
  const amounts = _.get(frequencySelected, ['amounts'], []);
  const amountOption = amounts.findIndex(am => am.amount_m === data.amount_m);
  const amount = data.showCustomAmount ? data.customAmount_m : data.amount_m;
  const calculatedAmount = calculateAmount(amount);


  return [{
    'name': `donatie ${formContext.googleProductName.toLowerCase()} ${getCategorySuffix()}`,
    'id': `${getIdPrefix()}${_.isNil(data.customAmount_m) ? 'k' : 'v'}${formContext.proposition}`,
    'variant': freqMap[data.frequency].value,
    'price': amount,
    'dimension3': calculatedAmount.toFixed(2),
    'metric1': calculatedAmount.toFixed(2),
    'dimension4': _.isNil(data.customAmount_m) ? `keuzebedrag${amountOption + 1}` : 'vrijekeuze',
    'dimension5': getPeriod(),
    'brand': formContext.googleAnalyticsBrandName || 'nexios',
    'category': `donaties/${formContext.googleProductName.toLowerCase()}/${getCategorySuffix()}`,
    'quantity': 1
  }];
}

function getPageVariantName(formContext, formData) {
  return _.get(formData, ['previousPageVariantName']) || _.get(formContext, ['pageVariantName']);
}

function getProductNettoPrice(formData) {
  return _.get(formData, ['productUnitPrice_m']);
}

function getProductCategory(formContext, formData) {
  return _.isNil(formData.previousPageVariantName) ? 'product donatie/regulier' : 'donatie opvolg/regulier';
}

function getProductQuantity(formData) {
  return _.get(formData, ['productAmount']);
}

function renderOnTopAmountProduct(formContext, data) {
  return {
    'name': `${getPageVariantName(formContext, data)} gift`,
    'id': `${_.get(formContext, ['productCode'])} gift`,
    'price': _.get(data, ['onTopAmount_m']),
    'nettoprice': _.get(data, ['onTopAmount_m']),
    'category': _.isNil(data.previousPageVariantName) ? 'product donatie/gift' : 'donatie opvolg/gift',
    'variant': _.get(formContext, ['productName']),
    'quantity': '1'
  };
}

export function hasOnTopAmount(data) {
  const onTopAmount = _.get(data, ['onTopAmount_m'], '0.00');
  return parseAndGetBigNumber(onTopAmount).gt(0);
}

export function getProducts(formContext, state) {
  const data = getFormData(state);
  const products = [
    {
      'name': getPageVariantName(formContext, data),
      'id': _.get(formContext, ['productCode']),
      'price': getProductNettoPrice(data),
      'nettoprice': getProductNettoPrice(data),
      'category': getProductCategory(formContext, data),
      'variant': _.get(formContext, ['productName']),
      'quantity': getProductQuantity(data)
    }
  ];

  if (hasOnTopAmount(data)) {
    products.push(renderOnTopAmountProduct(formContext, data));
  }

  return products;
}

export function isProductForm(formContext) {
  return _.get(formContext, ['donationPageType']) === '20_product';
}
