import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

const Btn = styled.button`
  width: 100%;
  height: ${props => props.theme.button.height};
  line-height: ${props => props.theme.button.height};
  font-family: ${props => props.theme.button.fontFamily};
  font-size: ${props => props.theme.button.fontSize};
  font-style: normal;
  letter-spacing: 0.6px;
  text-align: center;
  color: ${props => props.theme.button.color};
  border-radius: ${props => props.theme.button.borderRadius};
  background-color: ${props => props.theme.button.backgroundColor};
  border: 1px solid transparent;
  border-bottom: ${props => props.theme.button.borderBottom};
  box-shadow: ${props => props.theme.button.boxShadow};
  cursor: pointer;
  padding: ${props => props.theme.button.padding};

  &:active {
    ${props => props.theme.button.active};
  }

  &:focus {
    ${props => props.theme.button.focus};
  }

  &:hover {
    ${props => props.theme.button.hover};
  }
`;

const primary = '#F7CC26';
const hover = '#F8D651';
Btn.defaultProps = {
  theme: {
    button: {
      padding: '1px 6px',
      borderRadius: '0px',
      fontFamily: '"Maax Bold", "Arial" ,"sans-serif"',
      fontSize: '20px',
      height: '56px',
      backgroundColor: primary,
      color: '#000',
      boxShadow: 'none',
      active: css`
        background-color: ${hover};
        border-bottom: none;
        box-shadow: none !important;
      `,
      hover: css`
        background-color: ${hover};
      `,
      focus: css`
        box-shadow: none;
      `
    }
  }
};

export default class Button extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
  };

  render () {
    const disabled = this.props.hasErrors;

    if (disabled) {
      return (
        <Btn
          disabled={this.props.disabled}>
          {this.props.label}
        </Btn>
      );
    }

    return (
      <Btn
        onClick={this.props.onClick}
        disabled={this.props.disabled}>
        {this.props.label}
      </Btn>
    );
  }
}
