import {connect} from 'react-redux';

const Wizard = connect(state => {
  return {
    isDonationCompleted: state.reducer.isDonationCompleted,
    isStep1Completed: state.reducer.isStep1Completed,
    isStep2Completed: state.reducer.isStep2Completed,
    isStep3Completed: state.reducer.isStep3Completed,
    isProductForm: state.reducer.isProductForm
  };
})(({render, isDonationCompleted, isStep1Completed, isStep2Completed, isStep3Completed, isProductForm}) => {
  return render({isDonationCompleted, isStep1Completed, isStep2Completed, isStep3Completed, isProductForm});
});

export default Wizard;
