import _ from 'lodash';

export default function formatFirstName (name) {
  if (_.isNil(name)) {
    return name;
  }

  return _.split(name, ' ')
    .filter(namePart => _.trim(namePart) !== '')
    .map(namePart => _.upperFirst(namePart))
    .join(' ');
}
