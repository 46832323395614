import React from 'react';
import moment from 'moment';
import {withRouter} from 'react-router-dom';
import LayoutContainer from '../../components/LayoutContainer';
import Title from '../../components/Title';
import Box from '../../components/Box';
import Button from '../../components/Button';
import MainContainer from '../../components/MainContainer';
import Logo from '../../components/Logo';
import ProgressBar from '../../components/ProgressBar';
import Input from '../../components/Input';
import Label from '../../components/Label';
import {Field, Form, FormValidation, resetSubmitting} from '@nexios/frontend-forms';
import ErrorMessage from '../../components/ErrorMessage';
import FieldWrapper from '../../components/FieldWrapper';
import _ from 'lodash';
import {
  updateSession,
  cleanseAddress
} from '../../reducer';
import {connect} from 'react-redux';
import Validation from '../../components/Validation';
import DateInput from '../../components/DateInput';
import Backbutton from '../../components/Backbutton';
import RadioButtonContainer from '../../components/RadioButtonContainer';
import {isBSN} from '@nexios/validation';
import formatFirstName from '../../utils/formatFirstName';
import formatInfix from '../../utils/formatInfix';
import formatLastName from '../../utils/formatLastName';
import {Helmet} from 'react-helmet';
import Telemarketing from '../../components/Telemarketing';
import styled from 'styled-components';
import useScrollToTop from '../../hooks/useScrollToTop';
import Teaser from '../../components/Teaser';
import formatPhone from '@nexios/phone-number-formatter';
import genders from '../../utils/genders';

const hasValue = val => !_.isNil(val) && _.trim(val) !== '';

const isAddressVisible = ctx => {
  if (ctx.donateAsOrganisation === true) {
    return ctx.organisation.isAddressVisible;
  }

  return ctx.person.isAddressVisible;
};

const TaxDeductible2 = ({formContext, save, history, cleanseAddress, resetSubmittingForm}) => {
  useScrollToTop();
  let cleansingAddress = false;

  const formConfig = {
    onSubmit: () => {
      if (cleansingAddress) {
        resetSubmittingForm();
      } else {
        save([
          'firstNames',
          'infix',
          'lastName',
          'gender',
          'postalCode',
          'houseNumber_i',
          'houseNumberAddition',
          'street',
          'city',
          'dateOfBirth',
          'bsn',
          'telephone',
          'email',
          'hasPartner',
          'partnerFirstNames',
          'partnerInfix',
          'partnerLastName',
          'partnerGender',
          'partnerDateOfBirth',
          'partnerEmail',
          'allowTelemarketing'
        ], () => {
          history.push(`/betaalwijze${history.location.search}`);
        });
      }
    },
    onInit: (ctx) => {
      ctx.setList('days', _.range(1, 31).map(value => ({label: `${value}`, value: `${value}`})));
      ctx.setList('genders', genders(formContext));
      ctx.setList('yesNo', [
        {
          label: 'Ja',
          value: 'yes'
        },
        {
          label: 'Nee',
          value: 'no'
        }
      ]);

      if (formContext.telemarketingOptIn !== 'none') {
        ctx.setDefaultValues({
          allowTelemarketing: true
        });
      }
    },
    onDataChanged: (ctx, callback) => {
      const postalCode = _.get(ctx, ['data', 'postalCode']);
      const previousPostalCode = _.get(ctx, ['previousData', 'postalCode']);
      const houseNumber = _.get(ctx, ['data', 'houseNumber_i']);
      const previousHouseNumber = _.get(ctx, ['previousData', 'houseNumber_i']);
      const houseNumberAddition = _.get(ctx, ['data', 'houseNumberAddition']);
      const previousHouseNumberAddition = _.get(ctx, ['previousData', 'houseNumberAddition']);

      const hasFieldChanged = field => _.get(ctx, ['data', field]) !== _.get(ctx, ['previousData', field]);
      const firstNamesChanged = hasFieldChanged('firstNames');
      const infixChanged = hasFieldChanged('infix');
      const lastNameChanged = hasFieldChanged('lastName');
      const partnerFirstNamesChanged = hasFieldChanged('partnerFirstNames');
      const partnerInfixChanged = hasFieldChanged('partnerInfix');
      const partnerLastNameChanged = hasFieldChanged('partnerLastName');

      if (firstNamesChanged) {
        ctx.setValues({
          firstNames: formatFirstName(ctx.data.firstNames)
        });
      }

      if (infixChanged) {
        ctx.setValues({
          infix: formatInfix(ctx.data.infix)
        });
      }

      if (lastNameChanged) {
        ctx.setValues({
          lastName: formatLastName(ctx.data.lastName)
        });
      }

      if (partnerFirstNamesChanged) {
        ctx.setValues({
          partnerFirstNames: formatFirstName(ctx.data.partnerFirstNames)
        });
      }

      if (partnerInfixChanged) {
        ctx.setValues({
          partnerInfix: formatInfix(ctx.data.partnerInfix)
        });
      }

      if (partnerLastNameChanged) {
        ctx.setValues({
          partnerLastName: formatLastName(ctx.data.partnerLastName)
        });
      }

      let isChanged = false;

      if (postalCode !== previousPostalCode || houseNumber !== previousHouseNumber || houseNumberAddition !== previousHouseNumberAddition) {
        isChanged = true;
      }

      if (hasValue(postalCode) && hasValue(houseNumber) && isChanged) {

        // if we are already cleansing do not start again, request may interfere
        if (cleansingAddress) {
          return;
        }

        cleansingAddress = true;

        cleanseAddress({
          postalCode,
          houseNumber_i: houseNumber,
          houseNumberAddition
        }, (data) => {
          if (data.status === 500) {
            data = {
              result: {
                success: true
              },
              address: {
                street: '',
                city: ''
              }
            };
          }

          const addressCleansingStatus = _.get(data, ['result', 'success'], false) ? 'GREEN' : 'RED';

          const cleansedAddress = _.get(data, ['result', 'address']);

          if (addressCleansingStatus === 'GREEN') {
            ctx.setValues({
              street: cleansedAddress.street,
              city: cleansedAddress.city,
              postalCode: cleansedAddress.postalCode,
              houseNumber_i: cleansedAddress.houseNumber_i,
              houseNumberAddition: cleansedAddress.houseNumberAddition,
              addressCleansingStatus
            });
          } else {
            ctx.setValues({
              street: null,
              city: null,
              addressCleansingStatus
            });
          }

          ctx.validate(true);

          cleansingAddress = false;

          return callback();
        });
      } else if (!hasValue(postalCode) || !hasValue(houseNumber)) {
        ctx.setValues({
          addressCleansingStatus: null,
          street: null,
          city: null
        });
      }

      const email = _.get(ctx, ['data', 'email']);
      const previousEmail = _.get(ctx, ['previousData', 'email']);

      if (hasValue(email) && email !== previousEmail) {
        ctx.setValues({
          email: _.toLower(_.trim(email))
        });
      }

      return callback();
    },
    fields: {
      telemarketingInfoText: {
        isVisible: ctx => {
          if (ctx.telemarketingOptIn !== 'text') {
            return false;
          }

          if (ctx.donateAsOrganisation === true) {
            return ctx.organisation.isTelephoneVisible;
          }

          return ctx.person.isTelephoneVisible;
        },
        isRequired: false
      },
      allowTelemarketing: {
        isVisible: ctx => {
          if (ctx.telemarketingOptIn !== 'checkbox') {
            return false;
          }

          if (ctx.donateAsOrganisation === true) {
            return ctx.organisation.isTelephoneVisible;
          }

          return ctx.person.isTelephoneVisible;
        },
        isRequired: false
      },
      email: {
        isVisible: true,
        isRequired: true,
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.email) && ctx.email !== ''
          },
          {
            isValid: ctx => {
              if (_.isNil(ctx.email) || ctx.email === '') {
                return true;
              }

              return /^[-0-9a-zA-Z.+_]{1,63}@([-0-9a-zA-Z+_]+\.){1,63}[a-zA-Z]{1,63}$/.test(_.trim(ctx.email));
            },
            message: 'Er is geen geldig e-mailadres ingevuld.'
          }
        ]
      },
      gender: {
        isVisible: true,
        isRequired: true,
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.gender) && _.trim(ctx.gender) !== ''
          }
        ]
      },
      firstNames: {
        isVisible: true,
        isRequired: true,
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.firstNames) && _.trim(ctx.firstNames) !== ''
          },
          {
            isValid: ctx => {
              if (_.isNil(ctx.firstNames) || ctx.firstNames === '') {
                return true;
              }

              return _.trim(ctx.firstNames).length > 1;
            },
            message: 'Voornamen moet minimaal 2 karakters hebben.'
          }
        ]
      },
      infix: {
        isVisible: true,
        isRequired: false
      },
      lastName: {
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.lastName) && ctx.lastName !== ''
          },
          {
            isValid: ctx => {
              if (_.isNil(ctx.lastName) || ctx.lastName === '') {
                return true;
              }

              return _.trim(ctx.lastName).length > 1;
            },
            message: 'Achternaam moet minimaal 2 karakters hebben.'
          }
        ]
      },
      postalCode: {
        isVisible: true,
        isRequired: ctx => true,
        rules: [
          {
            isValid: ctx => {
              if (!hasValue(ctx.postalCode)) {
                return true;
              }

              return /^[1-9][0-9]{3}\W?[a-zA-Z]{2}$/.test(_.trim(ctx.postalCode));
            },
            message: 'Er is geen geldige postcode ingevuld.'
          },
          {
            isValid: ctx => hasValue(ctx.postalCode)
          },
          {
            message: 'Het opgegeven adres kan niet worden gevonden.',
            isValid: ctx => ctx.addressCleansingStatus !== 'RED'
          }
        ]
      },
      houseNumber_i: {
        isVisible: true,
        isRequired: true,
        rules: [
          {
            isValid: ctx => ctx.addressCleansingStatus !== 'RED'
          },
          {
            isValid: ctx => {
              if (!hasValue(ctx.houseNumber_i)) {
                return true;
              }

              return ctx.houseNumber_i.length < 8 && /^\d*$/.test(ctx.houseNumber_i);
            },
            message: 'Er is geen geldig huisnummer ingevuld. Controleer of er alleen cijfers gebruikt zijn.'
          },
          {
            isValid: ctx => hasValue(ctx.houseNumber_i)
          }
        ]
      },
      houseNumberAddition: {
        isVisible: true,
        isRequired: false
      },
      telephone: {
        isVisible: true,
        isRequired: false,
        rules: [
          {
            isValid: ctx => {
              if (!hasValue(ctx.telephone)) {
                return true;
              }

              return formatPhone(ctx.telephone).result === 'GREEN';
            },
            message: 'Er is geen geldig telefoonnummer ingevuld.'
          }
        ]
      },
      dateOfBirth: {
        isVisible: true,
        isRequired: true,
        rules: [
          {
            isValid: ctx => hasValue(ctx.dateOfBirth)
          },
          {
            isValid: ctx => {
              if (!hasValue(ctx.dateOfBirth)) {
                return true;
              }

              const dateOfBirth = moment(ctx.dateOfBirth, 'YYYY-MM-DD');
              return moment().diff(dateOfBirth, 'years') >= 18;
            },
            message: 'U moet 18 jaar of ouder zijn om een periodiek schenken overeenkomst aan te gaan.'
          }
        ]
      },
      bsn: {
        isVisible: true,
        isRequired: true,
        rules: [
          {
            isValid: ctx => hasValue(ctx.bsn)
          },
          {
            isValid: ctx => {
              if (hasValue(ctx.bsn)) {
                return isBSN(ctx.bsn);
              }

              return true;
            },
            message: 'Er is geen geldig BSN ingevuld.'
          }
        ]
      },
      street: {
        isVisible: ctx => ctx.addressCleansingStatus === 'GREEN' && isAddressVisible(ctx)
      },
      city: {
        isVisible: ctx => ctx.addressCleansingStatus === 'GREEN' && isAddressVisible(ctx)
      },
      hasPartner: {
        isVisible: true,
        isRequired: true,
        rules: [
          {
            isValid: ctx => _.includes(['yes', 'no'], ctx.hasPartner)
          }
        ]
      },
      showPartnerFields: {
        isVisible: ctx => ctx.hasPartner === 'yes'
      },
      partnerGender: {
        isVisible: ctx => ctx.hasPartner === 'yes',
        isRequired: ctx => ctx.hasPartner === 'yes',
        rules: [
          {
            isValid: ctx => {
              if (ctx.hasPartner === 'yes') {
                return !_.isNil(ctx.partnerGender) && _.trim(ctx.partnerGender) !== '';
              }

              return true;
            }
          }
        ]
      },
      partnerFirstNames: {
        isVisible: ctx => ctx.hasPartner === 'yes',
        isRequired: ctx => ctx.hasPartner === 'yes',
        rules: [
          {
            isValid: ctx => {
              if (ctx.hasPartner === 'yes') {
                return !_.isNil(ctx.partnerFirstNames) && _.trim(ctx.partnerFirstNames) !== '';
              }
              return true;
            }
          },
          {
            isValid: ctx => {
              if (_.isNil(ctx.partnerFirstNames) || ctx.partnerFirstNames === '') {
                return true;
              }

              if (ctx.hasPartner === 'yes') {
                return _.trim(ctx.partnerFirstNames).length > 1;
              }

              return true;
            },
            message: 'Voornamen moet minimaal 2 karakters hebben.'
          }
        ]
      },
      partnerInfix: {
        isVisible: ctx => ctx.hasPartner === 'yes',
        isRequired: false
      },
      partnerLastName: {
        isVisible: ctx => ctx.hasPartner === 'yes',
        isRequired: ctx => ctx.hasPartner === 'yes',
        rules: [
          {
            isValid: ctx => {
              if (ctx.hasPartner === 'yes') {
                return !_.isNil(ctx.partnerLastName) && ctx.partnerLastName !== '';
              }

              return true;
            }
          },
          {
            isValid: ctx => {
              if (_.isNil(ctx.partnerLastName) || ctx.partnerLastName === '') {
                return true;
              }

              if (ctx.hasPartner === 'yes') {
                return _.trim(ctx.partnerLastName).length > 1;
              }

              return true;
            },
            message: 'Achternaam moet minimaal 2 karakters hebben.'
          }
        ]
      },
      partnerDateOfBirth: {
        isVisible: ctx => ctx.hasPartner === 'yes',
        isRequired: ctx => ctx.hasPartner === 'yes',
        rules: [
          {
            isValid: ctx => {
              if (ctx.hasPartner === 'yes') {
                return hasValue(ctx.partnerDateOfBirth);
              }

              return true;
            }
          }
        ]
      }
    }
  };

  return <>
    <Helmet>
      <title>{formContext.contactDataBrowserTitle}</title>
    </Helmet>
    <MainContainer
      backgroundImageUrl={formContext.backgroundImageUrl}
      backgroundImageTabletUrl={formContext.backgroundImageTabletUrl}
      backgroundImageMobileUrl={formContext.backgroundImageMobileUrl}
    />
    <LayoutContainer
      position={formContext.textPosition}
      header={<div>
        <div style={{float: 'left', display: 'inline-block'}}>
          <Logo url={formContext.websiteUrl} />
        </div>
      </div>}
      content={<Box>
        <Title title={formContext.step2Header}><ProgressBar progress='2' /></Title>
        <Form
          formInstanceKey='donate'
          formContext={formContext}
          formConfig={formConfig}>
          <FormValidation
            component={ErrorMessage}
            errorMessage='U heeft nog niet alle velden ingevuld.' />
          <div className='widgetContent'>
            <div className='marginBottom'>
              <h1>Persoonsgegevens</h1>
            </div>
            <Name />
            <Gender />
            <Address />
            <DateOfBirth />
            <Bsn />
            <Phone
              telemarketingOptIn={formContext.telemarketingOptIn}
              telemarketingInfoText={formContext.telemarketingInfoText}
              telemarketingCheckBoxText={formContext.telemarketingCheckBoxText}
            />
            <Email />
            <HasPartner />
            <Field
              name='showPartnerFields'
              component={PartnerSection}
            />
            <PartnerName />
            <PartnerGender />
            <PartnerDateOfBirth />
            <div className='marginBottom'>
              <FormValidation
                type='submit'
                label={formContext.contactDataCallToActionLabel || 'Betaalwijze invullen'}
                component={Button}
              />
            </div>
            <div>
              <Backbutton link={`/${history.location.search}`} />
            </div>
          </div>
        </Form>
      </Box>}
      sidebar={<Teaser header={formContext.pageTeaserHeader} body={formContext.pageTeaserBodyText} />}
      footer={formContext.footer}
    />
  </>;
};

const mapStateToProps = (state) => {
  return {
    formContext: state.reducer.formContext
  };
};

const mapDispatchToProps = (dispatch) => ({
  save: (fields, callback) => {
    dispatch(updateSession(fields, {
      isStep2Completed: true
    }, callback));
  },
  cleanseAddress: (data, callback) => {
    dispatch(cleanseAddress(data, callback));
  },
  resetSubmittingForm: () => dispatch(resetSubmitting('donate'))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TaxDeductible2));

const AddressRow = styled.div`
  display: flex;

  > * {
    margin: 0 0 0 5px;

    flex: 1;

    &:first-child {
      margin: 0;
      flex: 3;
    }
  }
`;

const Address = () => (
  <div className='marginBottom'>
    <Label label='Adres' />
    <AddressRow>
      <Field
        name='postalCode'
        component={FieldWrapper}
        inputComponent={Input}
        placeholder={'Postcode'}
        inline
        hideValidation
        autoComplete='postal-code'
      />
      <Field
        name='houseNumber_i'
        component={FieldWrapper}
        inputComponent={Input}
        placeholder={'Huisn'}
        inline
        hideValidation
        inputType='tel'
        autoComplete='36e4d2d3-f99a-427f-9279-093805b65729'
      />
      <Field
        name='houseNumberAddition'
        component={FieldWrapper}
        inputComponent={Input}
        placeholder={'Toev'}
        hideValidation
        autoComplete='df4b280b-0f18-43e4-9b2c-3d046b7bde95'
      />
    </AddressRow>
    <Field
      name='postalCode'
      component={Validation} />
    <Field
      name='houseNumber_i'
      component={Validation} />
    <Field
      name='houseNumberAddition'
      component={Validation} />
    <Field
      component={({value}) => <div className='addressBox'>{value}&nbsp;</div>}
      name='street' />
    <Field
      component={({value}) => <div className='addressBox'>{value}</div>}
      name='city' />
  </div>
);

const Bsn = () => (
  <div className='marginBottom'>
    <Field
      label='Burgerservicenummer (BSN)'
      name='bsn'
      inputComponent={Input}
      component={FieldWrapper}
      placeholder='BSN'
      autoComplete='0a525b8b-ac21-4f2d-9928-fa1973c65828'
    />
  </div>
);

const Email = () => (
  <div className='marginBottom'>
    <Field
      label='E-mailadres'
      name='email'
      inputComponent={Input}
      component={FieldWrapper}
      placeholder='E-mailadres'
      inputType='email'
      autoComplete='email'
    />
  </div>
);

const Phone = (props) => (<>
  <div className='marginBottom'>
    <Field
      label='Telefoonnummer'
      name='telephone'
      component={FieldWrapper}
      inputComponent={Input}
      placeholder='Telefoonnummer'
      inputType='tel'
      autoComplete='tel'
    />
  </div>
  <Telemarketing {...props} />
</>);

const DateOfBirth = () => (
  <div className='marginBottom'>
    <Field
      label='Geboortedatum'
      name='dateOfBirth'
      component={FieldWrapper}
      inputComponent={DateInput}
      autoComplete='4959527a-2abd-4a32-82fd-3d8e2d2c0ffb'
    />
  </div>
);

const Gender = () => (
  <div className='marginBottom'>
    <Field
      label='Geslacht'
      name='gender'
      list='genders'
      component={FieldWrapper}
      inputComponent={(props) => {
        return <RadioButtonContainer fieldName='gender' valueName='gender' list={props.list} {...props} />;
      }}
    />
  </div>
);

const NameRow = styled.div`
  display: flex;

  > * {
    margin: 0 0 0 5px;

    flex: 1;

    &:first-child {
      margin: 0;
    }

    &:last-child {
      flex: 3;
    }
  }
`;

const Name = () => (
  <div className='marginBottom'>
    <Label label='Naam (Voornamen voluit)' />
    <div className='marginBottomTen'>
      <Field
        name='firstNames'
        component={FieldWrapper}
        inputComponent={Input}
        placeholder={'Voornamen'}
        hideValidation
        autoComplete='given-name'
      />
    </div>
    <NameRow>
      <Field
        name='infix'
        component={FieldWrapper}
        inputComponent={Input}
        placeholder={'Tussenv'}
        hideValidation
        autoComplete='7e7f81de-b9f2-4cb4-9b4f-6061f27e3809'
      />
      <Field
        name='lastName'
        component={FieldWrapper}
        inputComponent={Input}
        placeholder={'Achternaam'}
        hideValidation
        autoComplete='family-name'
      />
    </NameRow>
    <Field
      name='firstNames'
      component={Validation} />
    <Field
      name='infix'
      component={Validation} />
    <Field
      name='lastName'
      component={Validation} />
  </div>
);

const HasPartner = () => (
  <div className='marginBottom'>
    <Label label='Heeft u een echtgenoot / geregistreerd partner?' />
    <Field
      name='hasPartner'
      list='yesNo'
      component={FieldWrapper}
      inputComponent={(props) => {
        return <RadioButtonContainer fieldName='hasPartner' valueName='hasPartner' list={props.list} {...props} />;
      }}
    />
  </div>
);

const PartnerSection = () => (
  <div className='marginBottom'>
    <h1>Gegevens partner</h1>
  </div>
);

const PartnerName = () => (
  <div className='marginBottom'>
    <div className='marginBottomTen'>
      <Field
        name='partnerFirstNames'
        component={FieldWrapper}
        inputComponent={Input}
        placeholder={'Voornamen'}
        hideValidation
        label='Naam (Voornamen voluit)'
        autoComplete='2023ccab-d117-431a-bc42-41d2361e8af3'
      />
    </div>
    <NameRow>
      <Field
        name='partnerInfix'
        component={FieldWrapper}
        inputComponent={Input}
        placeholder={'Tussenv'}
        hideValidation
        autoComplete='3473505a-21e2-45ad-bd1e-5bb5220bda97'
      />
      <Field
        name='partnerLastName'
        component={FieldWrapper}
        inputComponent={Input}
        placeholder={'Achternaam'}
        hideValidation
        autoComplete='d3eb1f50-23f1-45e1-8d16-736dc6ef1672'
      />
    </NameRow>
    <Field
      name='partnerFirstNames'
      component={Validation} />
    <Field
      name='partnerInfix'
      component={Validation} />
    <Field
      name='partnerLastName'
      component={Validation} />
  </div>
);

const PartnerGender = () => (
  <div className='marginBottom'>
    <Field
      label='Geslacht'
      name='partnerGender'
      list='genders'
      component={FieldWrapper}
      inputComponent={(props) => {
        return <RadioButtonContainer fieldName='partnerGender' valueName='partnerGender'
                                     list={props.list} {...props} />;
      }}
    />
  </div>
);

const PartnerDateOfBirth = () => (
  <div className='marginBottom'>
    <Field
      label='Geboortedatum'
      name='partnerDateOfBirth'
      component={FieldWrapper}
      inputComponent={DateInput}
      autoComplete='57485272-98a3-4905-83c8-992b659138d4'
    />
  </div>
);
