import {Redirect, Route, Switch} from 'react-router-dom';
import DonationAlreadyCompleted from './views/DonationAlreadyCompleted';
import Step1 from './views/Step1';
import InvalidFlow from './views/InvalidFlow';
import Step3 from './views/Step3';
import Step4 from './views/Step4';
import GoogleAnalyticsWrapper from './views/GoogleAnalyticsWrapper';
import React from 'react';
import Wizard from './Wizard';

export default function DonationAnonRoutes () {
  return (
    <Switch>
      <Route
        path='/'
        exact>
        <Wizard render={({isDonationCompleted}) => (
          isDonationCompleted ? <DonationAlreadyCompleted /> : <Step1 next='/betalen' progressBar='1of2' />
        )} />
      </Route>
      <Route
        path='/betalen'>
        <Wizard render={({isDonationCompleted, isStep1Completed}) => (
          isDonationCompleted ? <DonationAlreadyCompleted /> : isStep1Completed ? <Step3 previous='/' progressBar='2of2' /> : <InvalidFlow />
        )} />
      </Route>
      <Route
        path='/bedankt'>
        <Wizard render={({isStep3Completed}) => (
          isStep3Completed ? <Step4 /> : <GoogleAnalyticsWrapper />
        )} />
      </Route>
      <Redirect to='/' />
    </Switch>
  );
}
