export default function(formContext) {
  if(formContext.hideGenderX) {
    return [
      {
        label: 'Man',
        value: 'male'
      },
      {
        label: 'Vrouw',
        value: 'female'
      }
    ];
  }

  return [
    {
      label: 'Man',
      value: 'male'
    },
    {
      label: 'Vrouw',
      value: 'female'
    },
    {
      label: 'X',
      value: 'x'
    }
  ];
}
