import {css} from 'styled-components';
import logoFsh from './icons/fsh/logo-fsh.png';
import progress1 from './icons/fsh/step-indicator-1.svg';
import progress2 from './icons/fsh/step-indicator-2.svg';
import progress3 from './icons/fsh/step-indicator-3.svg';
import progress1of2 from './icons/fsh/step-indicator-1of2.svg';
import progress2of2 from './icons/fsh/step-indicator-2of2.svg';
import whatsapp from './icons/sho/whatsapp.png';
import facebook from './icons/sho/facebook.svg';
import linkedin from './icons/sho/linkedin.svg';
import instagram from './icons/sho/instagram.svg';
import twitter from './icons/sho/x.svg';

const primaryColor = '#FE5000';
const primary = '#FE5000';
const hover = '#373A36';
const errorColor = '#790000';

export default {
  // GlobalStyles
  bodyFontFamily: '"DINPro", "Arial", "sans-serif"',
  h1FontFamily: '"DINPro Bold", "Arial", "sans-serif"',
  h3FontFamily: '"DINPro", "Arial", "sans-serif"',
  h1FontSize: '20px',
  bodyFontSize: '18px',
  // AmountButton
  amountButton: {
    padding: 0,
    color: primaryColor,
    fontFamily: '"DINPro Bold", "Arial", "sans-serif"',
    fontSize: '18px',
    width: '94px',
    height: '70px',
    border: '1px solid #B7C3C1',
    borderRadius: '4px',
    backgroundColor: '#fff',
    backgroundColorActive: '#F1F1F1',
    borderHover: `1px solid ${primaryColor}`,
    boxShadowHover: `inset 0px 0px 0px 1px ${primaryColor}`
  },
  buttonContainerSpaceBetween: {
    width: '94px'
  },
  //BackButton
  backButton: {
    fontSize: '16px',
    color: '#000'
  },
  // Box
  boxComponent: {
    borderRadius: '0'
  },
  // Button
  button: {
    padding: 0,
    fontFamily: '"DINPro Bold", "Arial" ,"sans-serif"',
    fontSize: '20px',
    height: '50px',
    backgroundColor: primary,
    color: '#fff',
    boxShadow: 'none',
    active: css`
      background-color: ${hover};
      border-bottom: none;
      box-shadow: none !important;
    `,
    hover: css`
      background-color: ${hover};
    `,
    focus: css`
      box-shadow: none;
    `
  },
  // Checkbox
  inputCheckbox: {
    border: '1px solid #B7C3C1'
  },
  box: {
    fontSize: '16px',
    colorHasError: errorColor,
    borderHasError: `1px solid ${errorColor}`,
    borderHover: '1px solid #818181',
    backgroundColorChecked: primaryColor,
    backgroundBorderChecked: '1px solid #199A57'
  },
  // DateInput
  dateDropdown: {
    padding: '10px 30px 12.4px 10px',
    border: '1px solid #B7C3C1',
    boxShadowFocus: '0 0 0 3px #B7C3C1',
    borderFocus: '1px solid transparent',
    boxShadowError: '0 0 0 1px #790000',
    boxShadowValid: '0 0 0 1px #199A57'
  },
  // DisplayIban
  iban: {
    fontFamily: '"DINPro Bold", "Arial", "sans-serif"',
    color: '#fff'
  },
  // Dropdown
  dropdown: {
    height: '46px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    fontSize: '18px',
    paddingLeft: '13px',
    border: '1px solid #B7C3C1',
    borderFocus: '1px solid transparent',
    boxShadowFocus: '0 0 0 2px #818181'
  },
  // ErrorMessage
  error: {
    backgroundColor: '#f3f3f3',
    color: '#790000',
    height: '49px'
  },
  // Iban
  ibanContainer: {
    display: 'none'
  },
  // Input
  inputComponent: {
    fontSize: '18px',
    border: '1px solid #B7C3C1',
    borderFocus: '1px solid #818181',
    boxShadowFocus: 'none',
    colorPlaceholder: '#B7C3C1',
    borderValid: '1px solid #199a57',
    boxShadowValid: 'none',
    backgroundColorValid: '#F3FAF6',
    borderError: '1px solid #790000',
    boxShadowError: 'none',
    colorError: '#790000',
    backgroundColorError: '#FFE5E6'
  },
  // TextArea
  textAreaComponent: {
    fontSize: '18px',
    border: '1px solid #B7C3C1',
    borderFocus: '1px solid #818181',
    boxShadowFocus: 'none',
    colorPlaceholder: '#B7C3C1',
    borderValid: '1px solid #199a57',
    boxShadowValid: 'none',
    backgroundColorValid: '#F3FAF6',
    borderError: '1px solid #790000',
    boxShadowError: 'none',
    colorError: '#790000',
    backgroundColorError: '#FFE5E6'
  },
  // Label
  optionalLabel: {
    color: '#818181'
  },
  label: {
    fontSize: '16px',
    color: '#000',
    colorError: '#790000'
  },
  logo: {
    width: '150px',
    height: '70px',
    backgroundImage: logoFsh,
    widthMobile: '180px',
    heightMobile: '60px',
    margin: '20px 0 0 0',
    marginMobile: '0'
  },
  // PaymentButton
  paymentButton: {
    color: primaryColor,
    fontFamily: '"DINPro", "Arial", "sans-serif"',
    fontSize: '14px',
    width: '115px',
    height: '78px',
    lineHeight: '78px',
    border: '1px solid #B7C3C1',
    borderRadius: '4px',
    backgroundColor: '#fff',
    backgroundColorActive: '#F1F1F1',
    borderHover: `1px solid ${primaryColor}`,
    boxShadowHover: `inset 0px 0px 0px 1px ${primaryColor}`
  },
  // RadioButton
  inputRadioButton: {
    backgroundColor: '#fff',
    border: '1px solid #B7C3C1',
    backgroundColorActive: '#199a57',
    borderActive: '1px solid #199a57'
  },
  // ProgressBar
  progressBar: {
    step1: css`
      width: 70px;
      background: url(${progress1}) no-repeat 0;
    `,
    step2: css`
      width: 70px;
      background: url(${progress2}) no-repeat 0;
    `,
    step3: css`
      width: 70px;
      background: url(${progress3}) no-repeat 0;
    `,
    step1of2: css`
      width: 40px;
      background: url(${progress1of2}) no-repeat 0;
    `,
    step2of2: css`
      width: 40px;
      background: url(${progress2of2}) no-repeat 0;
    `
  },
  radio: {
    borderHover: '1px solid #818181',
    colorHasError: '#790000',
    borderHasError: '1px solid #790000'
  },
  // TeaserHeader
  teaser: {
    display: 'inline',
    fontFamily: '"DINPro Bold", "Arial", "sans-serif"',
    fontWeight: 'normal',
    fontSize: '35px',
    lineHeight: '55px',
    fontSizeTablet: '35px',
    lineHeightTablet: '55px',
    fontSizeWide: '35px',
    lineHeightWide: '55px',
    subtitleFontFamily: '"DINPro Bold", "Arial", "sans-serif"',
    subtitleFontSize: '20px',
    subtitleLineHeight: '25px',
    subtitleFontSizeWide: '20px',
    subtitleLineHeightWide: '25px',
    subtitleFontSizeTablet: '20px',
    subtitleLineHeightTablet: '25px',
    backgroundColor: 'rgba(55,58,54,0.85)',
    backgroundColorSubtitle: 'rgba(55,58,54,0.85)',
    color: '#fff',
    colorSubtitle: '#fff',
    padding: '0 17px 0 17px',
    paddingTop: '10px',
    paddingTopSubtitle: '5px',
    topLeftTextAlignment: 'left',
    topRightTextAlignment: 'right',
    bottomLeftTextAlignment: 'left',
    bottomRightTextAlignment: 'right'
  },
  teaserContainer: {
    margin: '0'
  },
  // Title
  title: {
    color: '#fff',
    fontFamily: '"DINPro Bold", "Arial", "sans-serif"',
    fontSize: '26px',
    height: '65px',
    fontWeight: 'normal',
    borderRadius: '0',
    backgroundColor: '#373A36'
  },
  // Validation
  validation: {
    color: '#790000',
    fontSize: '16px'
  },
  // OptIn
  optInButton: {
    fontFamily: 'DINPro Bold',
    fontSize: '20px',
    backgroundColor: '#B7C3C1',
    color: '#fff',
    boxShadow: 'none',
    active: css`
      background-color: #B7C3C1;
      border-bottom: none;
      box-shadow: none !important;
    `,
    hover: css`
      background-color: #B7C3C1;
    `,
    focus: css`
      box-shadow: none;
    `
  },
  // SocialShareButton
  socialShareButton: {
    backgroundSize: '50px',
    color: '#000',
    whatsapp: css`background-image: url(${whatsapp})`,
    facebook: css`background-image: url(${facebook})`,
    twitter: css`background-image: url(${twitter})`,
    linkedin: css`background-image: url(${linkedin})`,
    instagram: css`background-image: url(${instagram})`
  }
};


