import React from 'react';
import LayoutContainer from '../../components/LayoutContainer';
import Box from '../../components/Box';
import MainContainer from '../../components/MainContainer';
import Logo from '../../components/Logo';
import SocialShareButton from '../../components/SocialShareButton';
import {Field, Form} from '@nexios/frontend-forms';
import parseText from '../../utils/parseText';
import {Helmet} from 'react-helmet';
import useScrollToTop from '../../hooks/useScrollToTop';

const View = ({formContext, theme, titleField, textField}) => {
  useScrollToTop();
  const socialMediaTitle = encodeURIComponent(formContext.socialMediaTitle);
  const url = encodeURIComponent(document.querySelector('meta[property=\'og:url\']').getAttribute('content'));
  const facebookUrl = `https://www.facebook.com/sharer.php?u=${url}&t=${socialMediaTitle}`;
  const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
  const whatsappUrl = `https://wa.me/?text=${socialMediaTitle}%20${url}`;
  let twitterUrl;

  if (formContext.twitterTitle) {
    const twitterTitle = encodeURIComponent(formContext.twitterTitle);
    twitterUrl = `https://twitter.com/share?text=${twitterTitle}&url=${url}`;
  } else {
    twitterUrl = `https://twitter.com/share?text=${socialMediaTitle}&url=${url}`;
  }
  const formConfig = {
    onSubmit: () => {},
    onDataChanged: () => {},
    onInit: (ctx) => {
      ctx.setValues({
        thankYouTitle: parseText(ctx, formContext[titleField]),
        thankYouText: parseText({...ctx, ...formContext}, formContext[textField], theme)
      });
    },
    fields: {}
  };

  return <>
    <Helmet>
      <title>{formContext.thankYouBrowserTitle}</title>
    </Helmet>
    <MainContainer
      backgroundImageUrl={formContext.backgroundImageUrl}
      backgroundImageTabletUrl={formContext.backgroundImageTabletUrl}
      backgroundImageMobileUrl={formContext.backgroundImageMobileUrl}
    />
    <LayoutContainer
      position={formContext.textPosition}
      header={<div>
        <div style={{float: 'left', display: 'inline-block'}}>
          <Logo url={formContext.websiteUrl} />
        </div>
      </div>}
      content={<><Box>
        <Form
          formInstanceKey='donate'
          formContext={formContext}
          formConfig={formConfig}>
          <Box>
            <div className='widgetContent'>
              <Field
                name='thankYouTitle'
                component={({value}) => (
                  <h1>{value}</h1>
                )} />
              <Field
                name='thankYouText'
                component={({value}) => (
                  <>
                    <div className='field'>
                      <div dangerouslySetInnerHTML={{__html: value}} />
                    </div>
                  </>
                )} />
              {formContext.allowSocialShare && <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <SocialShareButton name='whatsapp' url={whatsappUrl} />
                <SocialShareButton name='facebook' url={facebookUrl} />
                <SocialShareButton name='linkedin' url={linkedinUrl} />
                <SocialShareButton name='twitter' url={twitterUrl} />
              </div>}
            </div>
          </Box>
        </Form>
      </Box>
        {formContext.allowCallToAction &&
        <div dangerouslySetInnerHTML={{__html: formContext.callToAction}} />}
      </>}
      footer={formContext.footer}
    />
  </>;
};

export default View;

