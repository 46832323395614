import React from 'react';
import {withRouter} from 'react-router-dom';
import LayoutContainer from '../../components/LayoutContainer';
import Title from '../../components/Title';
import Box from '../../components/Box';
import Button from '../../components/Button';
import MainContainer from '../../components/MainContainer';
import Logo from '../../components/Logo';
import ProgressBar from '../../components/ProgressBar';
import IBAN from 'iban';
import PaymentMethodButtonContainer from '../../components/PaymentMethodButtonContainer';
import Checkbox from '../../components/Checkbox';
import ModalDialog from '../../components/ModalDialog';
import _ from 'lodash';
import parseText from '../../utils/parseText';
import {renderDialog, closeDialog, saveTaxDeductible} from '../../reducer';
import {Field, Form, FormValidation} from '@nexios/frontend-forms';
import {connect} from 'react-redux';
import Paragraph from '../../components/Paragraph';
import ErrorMessage from '../../components/ErrorMessage';
import FieldWrapper from '../../components/FieldWrapper';
import Backbutton from '../../components/Backbutton';
import Dropdown from '../../components/Dropdown';
import Input from '../../components/Input';
import Label from '../../components/Label';
import RadioButtonContainer from '../../components/RadioButtonContainer';
import {Helmet} from 'react-helmet';
import useScrollToTop from '../../hooks/useScrollToTop';
import Teaser from '../../components/Teaser';

const parseAuthorizeLabel = (txt, click) => {
  if (txt === undefined) {
    return;
  }
  const split = txt.split(/{{privacyStatement:(.*)}}/);
  if (split.length < 2) {
    return txt;
  }
  const params = split[1].split('@');
  const href = params.length > 1 ?
    <a href={(params[1].startsWith('http') ? '' : 'https://') + params[1]} target='_blank' rel='noopener noreferrer'>{params[0]}</a> :
    <a href='./' onClick={click}>{params[0]}</a>

  return <React.Fragment>
    {split[0]}
    {href}
    {split[2]}
  </React.Fragment>;
};

const TaxDeductible3 = ({formContext, isDialogVisible, renderDialog, closeDialog, save, history}) => {
  useScrollToTop();
  const formConfig = {
    onSubmit: () => {
      save([
        'paymentMethod',
        'frequency',
        'iban',
        'createManualFinancialTransactionRequest',
        'partnerAgrees',
        'taxDeductibleManualTransferText',
        'authorize',
        'startNextYear'
      ], history);
    },
    onInit: (ctx) => {
      ctx.setList('frequencies', [
        {
          label: 'Per maand, in 12 gelijke delen',
          value: 'monthly'
        },
        {
          label: 'Per kwartaal, in 4 gelijke delen',
          value: 'quarterly'
        },
        {
          label: 'Per half jaar, in 2 gelijke delen',
          value: 'halfYearly'
        },
        {
          label: 'Per jaar',
          value: 'yearly'
        }
      ]);
      ctx.setList('paymentMethods', [
        {
          label: 'Machtiging',
          value: 'directDebit'
        },
        {
          label: 'Zelf overmaken',
          value: 'manualTransfer'
        }
      ]);
      ctx.setList('yesNo', [
        {
          label: 'Ja',
          value: 'yes'
        },
        {
          label: 'Nee',
          value: 'no'
        }
      ]);
      const frequency = formContext.frequencies.find(f => f.value === 'yearly');
      const authorizeLabel = formContext.privacyConfirmationTextPerson;

      ctx.setList('amounts', frequency.amounts.map(a => ({
        label: a.name,
        value: a.amount_m.toString()
      })));

      ctx.setValues({
        paymentMethod: ctx.paymentMethod || 'directDebit',
        step3Header: parseText(ctx, _.get(formContext, ['step3Header'])),
        authorizeLabel,
        taxDeductibleMandateText: parseText(ctx, formContext.taxDeductibleMandateText),
        taxDeductibleManualTransferText: parseText(ctx, formContext.taxDeductibleManualTransferText),
        frequency: ctx.frequency || 'monthly',
        authorize: ctx.authorize === true,
        allowNewsletter: ctx.allowNewsletter === true,
        createManualFinancialTransactionRequest: ctx.createManualFinancialTransactionRequest || 'yes',
        partnerAgrees: ctx.partnerAgrees === true,
        startNextYear: ctx.startNextYear || 'no'
      });

      // set amountText on top of widget
      const amounts = _.get(frequency, ['amounts'], []);
      const theSelectedAmount = amounts.find(a => a.amount_m === ctx.amount_m);
      if (theSelectedAmount) {
        ctx.setValues({
          amountText: theSelectedAmount ? theSelectedAmount.message : null
        });
      }

      if (!formContext.authorizeAsCheckbox) {
        ctx.setValues({
          authorize: true
        });
      }
    },
    onDataChanged: (ctx) => {
      const startNextYear = _.get(ctx, ['data', 'startNextYear']);
      const previousStartNextYear = _.get(ctx, ['previousData', 'startNextYear']);
      const frequency = _.get(ctx, ['data', 'frequency']);
      const previousFrequency = _.get(ctx, ['previousData', 'frequency']);

      if ((startNextYear === 'yes' && startNextYear !== previousStartNextYear) || (frequency === 'yearly' && frequency !== previousFrequency)) {
        ctx.setValues({
          createManualFinancialTransactionRequest: 'no'
        });
      } else if(startNextYear !== 'yes' && frequency !== 'yearly' && frequency !== previousFrequency) {
        ctx.setValues({
          createManualFinancialTransactionRequest: 'yes'
        });
      }
    },
    fields: {
      paymentMethod: {
        isVisible: true,
        isRequired: true,
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.paymentMethod)
          }
        ]
      },
      frequency: {
        isVisible: ctx => ctx.paymentMethod === 'directDebit',
        isRequired: ctx => ctx.paymentMethod === 'directDebit',
        rules: [
          {
            isValid: ctx => {
              if (ctx => ctx.paymentMethod === 'directDebit') {
                return !_.isNil(ctx.frequency) && _.trim(ctx.frequency) !== '';
              }

              return true;
            }
          }
        ]
      },
      iban: {
        isVisible: true,
        isRequired: ctx => ctx.paymentMethod === 'directDebit',
        rules: [
          {
            isValid: ctx => {
              if (ctx.paymentMethod === 'directDebit') {
                return !_.isNil(ctx.iban) && ctx.iban !== '';
              }

              return true;
            }
          },
          {
            message: 'Vul hier een geldig IBAN nummer in.',
            isValid: ctx => {
              const value = ctx.iban;

              if (_.isNil(value) || value === '') {
                return true;
              }

              return IBAN.isValid(value);
            }
          }
        ]
      },
      createManualFinancialTransactionRequest: {
        isVisible: ctx => ctx.startNextYear !== 'yes' && ctx.paymentMethod === 'directDebit' && ctx.frequency !== 'yearly',
        isRequired: ctx => ctx.paymentMethod === 'directDebit' && ctx.frequency !== 'yearly',
        rules: [
          {
            isValid: ctx => _.includes(['yes', 'no'], ctx.createManualFinancialTransactionRequest)
          }
        ]
      },
      startNextYear: {
        isVisible: () => formContext.allowStartNextYear,
        isRequired: () => formContext.allowStartNextYear,
        rules: [
          {
            isValid: ctx => {
              if (formContext.allowStartNextYear) {
                return _.includes(['yes', 'no'], ctx.startNextYear);
              }

              return true;
            }
          }
        ]
      },
      partnerAgrees: {
        isVisible: ctx => ctx.hasPartner === 'yes',
        isRequired: ctx => ctx.hasPartner === 'yes',
        rules: [
          {
            isValid: ctx => {
              if (ctx.hasPartner === 'yes') {
                return ctx.partnerAgrees === true;
              }
              return true;
            }
          }
        ]
      },
      taxDeductibleManualTransferText: {
        isVisible: ctx => ctx.paymentMethod === 'manualTransfer'
      },
      authorize: {
        isVisible: true,
        isRequired: true,
        rules: [
          {
            isValid: ctx => ctx.authorize === true
          }
        ]
      }
    }
  };

  let content = <Box>
    <Title title={formContext.step3Header}><ProgressBar progress='3' /></Title>
    <Form
      formInstanceKey='donate'
      formContext={formContext}
      formConfig={formConfig}>
      {isDialogVisible && <ModalDialog close={() => {
        closeDialog();
      }}>
        <div dangerouslySetInnerHTML={{__html: formContext.privacyStatement}} />
      </ModalDialog>}
      {!isDialogVisible && <>
        <FormValidation
          component={ErrorMessage}
          errorMessage='U heeft nog niet alle velden ingevuld.' />
        <div className='widgetContent'>
          <div className='marginBottom'>
            <Field
              name='paymentMethod'
              listName='paymentMethods'
              component={(props) => {
                return <div className='marginBottom'>
                  <Label label='Ik wil betalen via ...' />
                  <PaymentMethodButtonContainer
                    fieldName='paymentMethod'
                    valueName='paymentMethod'
                    list={props.list} /></div>;
              }}
              list='paymentMethods'
            />
          </div>
          <div className='marginBottom'>
            <Field
              label='Rekeningnummer'
              name='iban'
              component={FieldWrapper}
              inputComponent={Input}
              placeholder='NL11ABNA012345678' />
          </div>
          <div className='marginBottom'>
            <Field
              label={<Field
                name='taxDeductibleMandateText'
                component={({value}) =>
                  <Paragraph>{value}</Paragraph>} />}
              name='frequency'
              list='frequencies'
              component={FieldWrapper}
              inputComponent={Dropdown}
            />
          </div>
          <div className='marginBottom'>
            <Field
              name='taxDeductibleManualTransferText'
              component={({value}) => {
                if (!_.isNil(value)) return <Paragraph>{value}</Paragraph>;
                return null;
              }} />
          </div>
          <Field
            name='startNextYear'
            label='Wilt u dat we de periodieke schenkingsovereenkomst starten in het nieuwe kalenderjaar per 1 januari?'
            list='yesNo'
            component={FieldWrapper}
            inputComponent={(props) => {
              return <RadioButtonContainer fieldName='startNextYear'
                                           valueName='startNextYear' {...props} />;
            }}
          />
          <Field
            name='createManualFinancialTransactionRequest'
            label='Wilt u dit jaar eenmalig een extra incasso, zodat u op het totale jaarbedrag uitkomt? (alleen als u per maand/kwartaal of half jaar doneert).'
            list='yesNo'
            component={FieldWrapper}
            inputComponent={(props) => {
              return <RadioButtonContainer fieldName='createManualFinancialTransactionRequest'
                                           valueName='createManualFinancialTransactionRequest' {...props} />;
            }}
          />
          <Field
            name='partnerAgrees'
            label='Mijn partner is akkoord met de aanvraag van deze overeenkomst.'
            component={FieldWrapper}
            inputComponent={Checkbox}
            hideLabel
          />
          <div className='marginBottom'>
            {formContext.authorizeAsCheckbox && <Field
              hideLabel
              name='authorize'
              component={FieldWrapper}
              inputComponent={Checkbox}
              label={<Field
                name='authorizeLabel'
                component={({value}) =>
                  <span>{parseAuthorizeLabel(value, renderDialog)}</span>} />} />}

            {!formContext.authorizeAsCheckbox && <Field
              name='authorizeLabel'
              component={({value}) =>
                <span>{parseAuthorizeLabel(value, renderDialog)}</span>} />}
          </div>
          <Field
            name='allowNewsletter'
            label={formContext.newsletterTextTaxDeductible || 'Houd mij op de hoogte over nieuwe ontwikkelingen.'}
            component={FieldWrapper}
            inputComponent={Checkbox}
            hideLabel
          />
          <div className='marginBottom'>
            <Label label='Als u deze aanvraag indient, ontvangt u na onze ondertekening een e-mail met uw overeenkomst.' />
          </div>
          <div className='marginBottom'>
            <FormValidation
              type='submit'
              label={formContext.paymentMethodMandateCallToActionLabel || 'Ondertekenen en versturen'}
              component={Button}
            />
          </div>
          <div>
            <Backbutton link={`/doneren${history.location.search}`} />
          </div>
        </div>
      </>}
    </Form>
  </Box>;

  return <>
    <Helmet>
      <title>{formContext.paymentMethodBrowserTitle}</title>
    </Helmet>
    <MainContainer
      backgroundImageUrl={formContext.backgroundImageUrl}
      backgroundImageTabletUrl={formContext.backgroundImageTabletUrl}
      backgroundImageMobileUrl={formContext.backgroundImageMobileUrl}
    />
    <LayoutContainer
      position={formContext.textPosition}
      header={<div>
        <div style={{float: 'left', display: 'inline-block'}}>
          <Logo url={formContext.websiteUrl} />
        </div>
      </div>}
      content={content}
      sidebar={<Teaser header={formContext.pageTeaserHeader} body={formContext.pageTeaserBodyText} />}
      footer={formContext.footer}
    />
  </>;
};

const mapStateToProps = (state) => {
  return {
    isDialogVisible: state.reducer.isDialogVisible,
    formContext: state.reducer.formContext
  };
};

const mapDispatchToProps = (dispatch) => ({
  save: (fields, history) => {
    dispatch(saveTaxDeductible(history));
  },
  renderDialog: (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(renderDialog());
  },
  closeDialog: () => {
    dispatch(closeDialog());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TaxDeductible3));
