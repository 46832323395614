import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import correct from '../../static/images/icons/correct.svg';
import error from '../../static/images/icons/error.svg';

const isValid = css`
  position: relative;
  border: ${props => props.theme.inputComponent.borderValid};
  box-shadow: ${props => props.theme.inputComponent.boxShadowValid};
  background-color: ${props => props.theme.inputComponent.backgroundColorValid};
`;

const hasErrors = css`
  color: ${props => props.theme.inputComponent.colorError};
  position: relative;
  border: ${props => props.theme.inputComponent.borderError};
  box-shadow: ${props => props.theme.inputComponent.boxShadowError};
  background-color: ${props => props.theme.inputComponent.backgroundColorError};
`;

const isRequired = css`
  color: ${props => props.theme.inputComponent.colorError};
  position: relative;
  border: ${props => props.theme.inputComponent.borderError};
  box-shadow: ${props => props.theme.inputComponent.boxShadowError};
  background-color: ${props => props.theme.inputComponent.backgroundColorError};
`;

const isValidInput = css`
  background: url(${correct}) no-repeat scroll calc(100% - 4px) 4px;
  background-color: ${props => props.theme.inputComponent.backgroundColorValid};
`;

const hasErrorsInput = css`
  color: ${props => props.theme.inputComponent.colorError};
  background: url(${error}) no-repeat scroll calc(100% - 4px) 4px;
  background-color: ${props => props.theme.inputComponent.backgroundColorError};
`;

const isRequiredInput = css`
  color: ${props => props.theme.inputComponent.colorError};
  background-color: ${props => props.theme.inputComponent.backgroundColorError};
`;

export const Container = styled.span`
  display: inline-flex;
  font-size: ${props => props.theme.inputComponent.fontSize};
  min-width: 0;
  width: 100%;
  box-sizing: border-box;
  height: 46px;
  padding: 10px 12px;
  border: ${props => props.theme.inputComponent.border};
  border-radius: ${props => props.theme.inputComponent.borderRadius || '4px'};
  background-color: #fff;

  ${props => props.isValid ? isValid : null}
  ${props => props.hasErrors && !props.isRequired ? hasErrors : null}
  ${props => props.isRequired ? isRequired : null}

  ${props => props.inline ? css`
    margin-right: 5%;
  ` : null}

  :focus-within {
    border: ${props => props.theme.inputComponent.borderFocus};
    box-shadow: ${props => props.theme.inputComponent.boxShadowFocus};
    background-color: ${props => props.theme.inputComponent.backgroundColorFocus};
  }
`;

// We use attrs to pass all props to underlying components.
export const InputComponent = styled.input.attrs(props => {
  return props;
})`
  font-size: ${props => props.theme.inputComponent.fontSize};
  min-width: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  appearance: none;

  ${props => props.isValid ? isValidInput : null}
  ${props => props.hasErrors && !props.isRequired ? hasErrorsInput : null}
  ${props => props.isRequired ? isRequiredInput : null}

  :focus {
    background-color: ${props => props.theme.inputComponent.backgroundColorFocus};
  }

  ::placeholder {
    color: ${props => props.theme.inputComponent.colorPlaceholder};
  }
`;

InputComponent.defaultProps = {
  theme: {
    inputComponent: {
      fontSize: '18px',
      border: '1px solid #c4c4c4',
      borderFocus: '1px solid #818181',
      boxShadowFocus: 'none',
      colorPlaceholder: '#c4c4c4',
      borderValid: '1px solid #199a57',
      boxShadowValid: 'none',
      backgroundColorValid: '#F3FAF6',
      borderError: '1px solid #E8141E',
      boxShadowError: 'none',
      colorError: '#E8141E',
      backgroundColorError: '#FFE5E6'
    }
  }
};

export default class Input extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    setValue: PropTypes.func,
    validate: PropTypes.func,
    value: PropTypes.string,
    isValid: PropTypes.bool,
    hasErrors: PropTypes.bool,
    isRequired: PropTypes.bool,
    width: PropTypes.number,
    inline: PropTypes.bool,
    inputType: PropTypes.string
  };

  static defaultProps = {
    width: 100,
    inline: false,
    inputType: 'text'
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onChange(e) {
    this.props.setValue(e.target.value);
  }

  onBlur(e) {
    this.props.validate();
  }

  render() {
    return (
      <Container isValid={this.props.isValid} isRequired={this.props.isRequired} hasErrors={this.props.hasErrors} style={{width: `${this.props.width}%`}}>
        <InputComponent
          {...this.props}
          style={{width: `${this.props.width}%`}}
          maxLength='1000'
          onChange={this.onChange}
          onBlur={this.onBlur}
        />
      </Container>
    );
  }
}
