import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: ${props => props.theme.amountButton.backgroundColorActive};
  border-radius: ${props => props.theme.amountButton.borderRadius};
  height: 46px;
  display: flex;
  align-items: center;
  padding: 8px 14px;

  > svg {
    margin-left: auto;
    fill: ${props => props.theme.amountButton.color};
  }
`
export default function SinglePeriod({label}) {
  return (
    <Container>
      <span>{label}</span>
      <svg xmlns='http://www.w3.org/2000/svg' width='20' height='19.977' viewBox='0 0 20 19.977'>
        <path id='calendar-check'
              d='M18.367,3.785H17.088V3.017a.767.767,0,0,0-1.535,0v.767H8.9V3.017a.767.767,0,0,0-1.535,0v.767H6.088A3.462,3.462,0,0,0,2.251,7.622V18.367A3.462,3.462,0,0,0,6.088,22.2H18.367A3.462,3.462,0,0,0,22.2,18.367V7.622a3.462,3.462,0,0,0-3.837-3.837ZM6.088,5.32H7.367v.767a.767.767,0,0,0,1.535,0V5.32h6.651v.767a.767.767,0,1,0,1.535,0V5.32h1.279c1.614,0,2.3.689,2.3,2.3V8.39H3.785V7.622C3.785,6.008,4.474,5.32,6.088,5.32ZM18.367,20.669H6.088c-1.614,0-2.3-.689-2.3-2.3V9.925H20.67v8.442C20.67,19.98,19.981,20.669,18.367,20.669Zm-3.039-7.623a.767.767,0,0,1,0,1.086L11.92,17.548a.765.765,0,0,1-1.085,0L9.127,15.839a.767.767,0,0,1,1.085-1.085l1.163,1.162,2.865-2.865a.766.766,0,0,1,1.088-.006Z'
              transform='translate(-2.227 -2.25)' />
      </svg>
    </Container>
  );
}
