import parse from 'html-react-parser';
import replaceNewLinesForHtml from '../../utils/replaceNewLines';
import _ from 'lodash';

export default function TextToHtml({text}) {
  const options = {
    replace: (props) => {
      if (props.type === 'text') {
        return replaceNewLinesForHtml(props.data);
      }
    }
  };

  if (_.isNil(text) || text === '') {
    return null;
  }

  if (text) {
    return parse(text, options);
  }
}
