import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled, {css, withTheme} from 'styled-components';
import correct from '../../static/images/icons/correct.svg';
import error from '../../static/images/icons/error.svg';

const isValid = css`
  position: relative;
  border: ${props => props.theme.textAreaComponent.borderValid};
  box-shadow: ${props => props.theme.textAreaComponent.boxShadowValid};
  background: url(${correct}) no-repeat scroll calc(100% - 6px) 14px;
  background-color: ${props => props.theme.textAreaComponent.backgroundColorValid};
`;

const hasErrors = css`
  color: ${props => props.theme.textAreaComponent.colorError};
  position: relative;
  border: ${props => props.theme.textAreaComponent.borderError};
  box-shadow: ${props => props.theme.textAreaComponent.boxShadowError};
  background: url(${error}) no-repeat scroll calc(100% - 6px) 14px;
  background-color: ${props => props.theme.textAreaComponent.backgroundColorError};
`;

const isRequired = css`
  color: ${props => props.theme.textAreaComponent.colorError};
  position: relative;
  border: ${props => props.theme.textAreaComponent.borderError};
  box-shadow: ${props => props.theme.textAreaComponent.boxShadowError};
  background-color: ${props => props.theme.textAreaComponent.backgroundColorError};
`;

// We use attrs to pass all props to underlying components.
export const TextAreaComponent = styled.textarea.attrs(props => {
  return props;
})`
  font-size: ${props => props.theme.textAreaComponent.fontSize};
  min-width: 0;
  max-width: 340px;
  width: 100%;
  box-sizing: border-box;
  padding: 17px 12px 17px 12px;
  border-radius: ${props => props.theme.textAreaComponent.borderRadius || '4px'};
  border: ${props => props.theme.textAreaComponent.border};
  appearance: none;

  ${props => props.isValid ? isValid : null}

  ${props => props.hasErrors && !props.isRequired ? hasErrors : null}

  ${props => props.isRequired ? isRequired : null}

  ${props => props.inline ? css`
    margin-right: 5%;
   ` : null}

  :focus {
    border: ${props => props.theme.textAreaComponent.borderFocus};
    box-shadow: ${props => props.theme.textAreaComponent.boxShadowFocus};
    outline: none;
  }

  ::placeholder {
    color: ${props => props.theme.textAreaComponent.colorPlaceholder};
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    max-width: 304px;
  }

  @media (min-width: 364px) and (max-width: 480px) {
    max-width: 304px;
  }
`;

TextAreaComponent.defaultProps = {
  theme: {
    textAreaComponent: {
      fontSize: '18px',
      border: '1px solid #c4c4c4',
      borderFocus: '1px solid #818181',
      boxShadowFocus: 'none',
      colorPlaceholder: '#c4c4c4',
      borderValid: '1px solid #199a57',
      boxShadowValid: 'none',
      backgroundColorValid: '#F3FAF6',
      borderError: '1px solid #E8141E',
      boxShadowError: 'none',
      colorError: '#E8141E',
      backgroundColorError: '#FFE5E6'
    }
  }
};

class TextArea extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    setValue: PropTypes.func,
    validate: PropTypes.func,
    value: PropTypes.string,
    isValid: PropTypes.bool,
    hasErrors: PropTypes.bool,
    isRequired: PropTypes.bool,
    width: PropTypes.number,
    inline: PropTypes.bool,
    theme: PropTypes.object.isRequired
  };

  static defaultProps = {
    width: 100,
    inline: false,
    rows: 3
  };

  constructor (props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onChange (e) {
    this.props.setValue(e.target.value);
  }

  onBlur (e) {
    this.props.validate();
  }

  render () {
    return (
      <TextAreaComponent
        {...this.props}
        style={{width: `${this.props.width}%`}}
        maxLength='1000'
        onChange={this.onChange}
        onBlur={this.onBlur}
        rows={this.props.theme.textAreaComponent.rows || this.props.rows}
      />
    );
  }
}

export default withTheme(TextArea);
