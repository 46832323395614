const _ = require('lodash');
const BigNumber = require('bignumber.js');

const getNumberValue = (text) => {
  if (!_.isString(text)) {
    return undefined;
  }

  var isNegative = /^-/.test(text);

  text = text.replace(/^-/, '');

  if (/^0x/.test(text)) {
    return isNegative ? '-' + text : text;
  }

  if (!/^[0-9.,]+$/.test(text)) {
    return undefined;
  }

  var formatted = text.replace(/[.,]([0-9]{1,2})$/, '|$1');

  var cleaned = formatted.replace(/[.,]/g, '');

  var parsed = cleaned.replace('|', '.').replace(/^0+(.+)/, '$1').replace(/^\./, '0.');

  return isNegative ? '-' + parsed : parsed;
};

export default (text) => {
  if (!_.isString(text)) {
    text = '' + text;
  }

  var parsed = getNumberValue(text);

  if (_.isNil(parsed)) {
    return undefined;
  }

  return new BigNumber(parsed);
};
