import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Error = styled.div`
  background-color: ${props => props.theme.error.backgroundColor};
  width: 100%;
  height: ${props => props.theme.error.height};
  line-height: ${props => props.theme.error.height};
  text-align: center;
  color: ${props => props.theme.error.color};
   font-size: 16px;
`;

Error.defaultProps = {
  theme: {
    error: {
      backgroundColor: '#f3f3f3',
      color: '#e8141e',
      height: '49px'
    }
  }
};

export default class ErrorMessage extends Component {
  static propTypes = {
    hasErrors: PropTypes.bool,
    errorMessage: PropTypes.string.isRequired
  };

  render () {
    if (!this.props.hasErrors) {
      return null;
    }

    return (
      <Error>{this.props.errorMessage}</Error>
    );
  }
}
