import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import progress1 from '../../themes/icons/sho/step-indicator-1.svg';
import progress2 from '../../themes/icons/sho/step-indicator-2.svg';
import progress3 from '../../themes/icons/sho/step-indicator-3.svg';
import progress1of2 from '../../themes/icons/sho/step-indicator-1of2.svg';
import progress2of2 from '../../themes/icons/sho/step-indicator-2of2.svg';

const Bar = styled.div`
  width: ${props => props.theme.progressBar.width};
  height: 14px;
  
  ${props => props.progress === '1' ? props.theme.progressBar.step1 : null}
  ${props => props.progress === '2' ? props.theme.progressBar.step2 : null}
  ${props => props.progress === '3' ? props.theme.progressBar.step3 : null}
  ${props => props.progress === '1of2' ? props.theme.progressBar.step1of2 : null}
  ${props => props.progress === '2of2' ? props.theme.progressBar.step2of2 : null}
`;

Bar.defaultProps = {
  theme: {
    progressBar: {
      width: '70px',
      step1: css`
        background: url(${progress1}) no-repeat 0;
      `,
      step2: css`
        background: url(${progress2}) no-repeat 0;
      `,
      step3: css`
        background: url(${progress3}) no-repeat 0;
      `,
      step1of2: css`
        background: url(${progress1of2}) no-repeat 0;
      `,
      step2of2: css`
        background: url(${progress2of2}) no-repeat 0;
      `
    }
  }
}

export default class ProgressBar extends Component {
  static propTypes = {
    progress: PropTypes.string.isRequired
  };

  render () {
    return (
      <Bar progress={this.props.progress} />
    );
  }
}
