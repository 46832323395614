import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const Button = styled.div`
    a {
      font-size: ${props => props.theme.backButton.fontSize};
      color: ${props => props.theme.backButton.color};
    }
`;

Button.defaultProps = {
  theme: {
    backButton: {
      fontSize: '16px',
      color: '#1A1A1A'
    }
  }
};

export default class BackButton extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    link: PropTypes.string
  };

  static defaultProps = {
    link: '/'
  };

  render () {
    return (
      <Button>
        <Link to={this.props.link} onClick={this.props.onClick}>Terug</Link>
      </Button>
    );
  }
}
