import React, {useState} from 'react';
import styled, {css} from 'styled-components';

const InputCheckbox = styled.span`
  position: absolute;
  left: 0;
  height: 22px;
  width: 22px;
  border: ${props => props.theme.inputCheckbox.border};
  border-width: ${props => props.hasFocus ? '2px' : null};

  :after {
    content: '';
    position: absolute;
    display: none;
  }
`;

const hasError = css`
  color: ${props => props.theme.box.colorHasError};

  ${InputCheckbox} {
    border: ${props => props.theme.box.borderHasError};
  }
`;

const Box = styled.div`
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  user-select: none;
  font-size: ${props => props.theme.box.fontSize};

  ${props => props.hasError ? hasError : null}
  :hover {
    ${InputCheckbox} {
      border: ${props => props.theme.box.borderHover};
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ ${InputCheckbox}:after {
    display: block;
  }

  input:checked ~ ${InputCheckbox} {
    background-color: ${props => props.theme.box.backgroundColorChecked};
    border: ${props => props.theme.box.backgroundBorderChecked};
  }

  ${InputCheckbox}:after {
    left: 6px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid ${props => props.theme.box.fillColorChecked || '#fff'};
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const CheckboxLabel = styled.label`
  color: ${props => props.subtle === true ? props.theme?.inputCheckbox?.label?.color || 'inherit' : 'inherit'};
  font-size: ${props => props.subtle === true ? props.theme?.inputCheckbox?.label?.fontSize || 'inherit' : 'inherit'};
`;

InputCheckbox.defaultProps = {
  theme: {
    inputCheckbox: {
      border: '1px solid #C4C4C4'
    }
  }
};

Box.defaultProps = {
  theme: {
    box: {
      fontSize: '16px',
      colorHasError: '#e8141e',
      borderHasError: '1px solid #e8141e',
      borderHover: '1px solid #818181',
      backgroundColorChecked: '#199A57',
      backgroundBorderChecked: '1px solid #199A57'
    }
  }
};

export default function Checkbox(props) {
  const [hasFocus, setHasFocus] = useState(false);
  const onChange = e => {
    props.setValue(e.target.checked);
    props.validate();
  };

  return (
    <Box hasError={props.hasErrors}>
      <CheckboxLabel subtle={!!props.subtle}>
        <input
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          name={props.name}
          type='checkbox'
          checked={props.isChecked}
          onChange={onChange} />
        <InputCheckbox hasFocus={hasFocus} />
        {props.label}
      </CheckboxLabel>
    </Box>
  );
}
