import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BoxComponent = styled.div`
  min-width: 300px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.35);
  border-radius: ${props => props.theme.boxComponent.borderRadius};
  border: ${props => props.theme.boxComponent.border}
`;

BoxComponent.defaultProps = {
  theme: {
    boxComponent: {
      borderRadius: '0'
    }
  }
}

export default class Box extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
  };

  render () {
    return (
      <BoxComponent>
        {this.props.children}
      </BoxComponent>
    );
  }
}
