export default function replaceNewLinesForHtml(value) {
  if (value === undefined || value === null) {
    return '';
  }

  const newLineRegExp = /\r?\n/;

  const values = value.split(newLineRegExp);
  return values.filter(v => v !== '' && v !== undefined && v !== null).join('<br />');
}
