import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import whatsapp from '../../themes/icons/sho/whatsapp.png';
import facebook from '../../themes/icons/sho/facebook.svg';
import linkedin from '../../themes/icons/sho/linkedin.svg';
import instagram from '../../themes/icons/sho/instagram.svg';
import twitter from '../../themes/icons/sho/logo-x.svg';

const Container = styled.div`
  cursor: pointer;
  text-align: center;
  color: ${props => props.theme.socialShareButton.color};
`;

const Button = styled.div`
  display: inline-block;
  min-width: 55px;
  width: 100%;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${props => props.theme.socialShareButton.backgroundSize};

    ${props => props.name === 'whatsapp' ? props.theme.socialShareButton.whatsapp : null}
    ${props => props.name === 'facebook' ? props.theme.socialShareButton.facebook : null}
    ${props => props.name === 'twitter' ? props.theme.socialShareButton.twitter : null}
    ${props => props.name === 'linkedin' ? props.theme.socialShareButton.linkedin : null}
    ${props => props.name === 'instagram' ? props.theme.socialShareButton.instagram : null}
`;

Button.defaultProps = {
  theme: {
    socialShareButton: {
      color: '#000',
      backgroundSize: '50px',
      whatsapp: css`background-image: url(${whatsapp})`,
      facebook: css`background-image: url(${facebook})`,
      twitter: css`background-image: url(${twitter})`,
      linkedin: css`background-image: url(${linkedin})`,
      instagram: css`background-image: url(${instagram})`
    }
  }
};

export default class SocialShareButton extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
    title: PropTypes.string
  };

  render () {

    return <Container onClick={() => window.open(this.props.url, '_blank')}>
      <Button name={this.props.name} />
    </Container>;
  }
}
