import _ from 'lodash';
import {PAYING, updateSession} from '../../reducer';
import {getFormData} from '../helpers';

export function gaPaymentFailure () {
  return (dispatch, getState) => {
    const state = getState();

    // If 'paying' is true client returned here because of failure in PSP or back
    if (state.reducer.isPaying) {

      // reset is paying in state and session
      dispatch({
        type: PAYING,
        isPaying: false
      });
      dispatch(updateSession(null, {
        isPaying: false
      }));

      // send payment failure to GA
      const action = /action=([^&]+)/;
      const actionResult = action.exec(window.location.href);
      const actionDescription = '';

      const map = {
        error: 'internal error',
        fail: 'payment unsuccessful',
        cancel: 'cancelled by user'
      };

      const failureActionIn = _.get(actionResult, '1', 'error');
      let failureActionOut = 'internal error';

      if (_.has(map, failureActionIn)) {
        failureActionOut = map[failureActionIn];
      }

      const data = getFormData(state);

      const failData = {
        'eventCategory': 'payment event',
        'eventAction': failureActionOut,
        'eventLabel': actionDescription,
        'transactionId': data.referenceKey,
        'eventValue': undefined,
        'eventNonInteraction': true,
        'event': 'eventPushPayment'
      };

      window.dataLayer.push(failData);
    }
  };
}
