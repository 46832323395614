import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TitleComponent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.theme.title.height};
  color: ${props => props.theme.title.color};
  background-color: ${props => props.theme.title.backgroundColor};
  padding: 0 17px 0 17px;
  line-height: ${props => props.theme.title.height};
  border-top-left-radius: ${props => props.theme.title.borderRadius};
  border-top-right-radius: ${props => props.theme.title.borderRadius};

  h1  {
    width: 220px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: ${props => props.theme.title.fontFamily};
    font-size: ${props => props.theme.title.fontSize};
    font-weight: ${props => props.theme.title.fontWeight};
    border-top-left-radius: ${props => props.theme.title.borderRadius};
  }
`;

TitleComponent.defaultProps = {
  theme: {
    title: {
      fontFamily: '"Maax Bold", "Arial", "sans-serif"',
      fontSize: '26px',
      height: '65px',
      fontWeight: 'normal',
      borderRadius: '0',
      backgroundColor: '#000',
      color: '#fff'
    }
  }
};

export default class Title extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.element
  };

  render () {
    return (
      <TitleComponent>
        <h1>{this.props.title}</h1>
        {this.props.children}
      </TitleComponent>
    );
  }
}
