import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

const isChecked = css`
    border: ${props => props.theme.amountButton.borderHover};
    box-shadow: ${props => props.theme.amountButton.boxShadowHover};
    background-color: ${props => props.theme.amountButton.backgroundColorActive};
`;

const Button = styled.button`
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
  font-family: ${props => props.theme.amountButton.fontFamily};
  font-size: ${props => props.theme.amountButton.fontSize};
  width: ${props => props.theme.amountButton.width};
  height: ${props => props.theme.amountButton.height};
  line-height: ${props => props.theme.amountButton.height};
  box-sizing: border-box;
  text-align: center;
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  border: ${props => props.theme.amountButton.border};
  border-radius: ${props => props.theme.amountButton.borderRadius};
  background-color: ${props => props.theme.amountButton.backgroundColor};
  padding: ${props => props.theme.amountButton.padding};

  color: ${props => props.isChecked ? props.theme.amountButton.color : props.theme.amountButton.inactiveColor || '#000'};

  ${props => props.isChecked ? isChecked : null}

  &:hover {
    border: ${props => props.theme.amountButton.borderHover};
    box-shadow: ${props => props.theme.amountButton.boxShadowHover};
    color: ${props => props.theme.amountButton.color};
  }

  &:focus {
    border: ${props => props.theme.amountButton.borderHover};
    box-shadow: ${props => props.theme.amountButton.boxShadowHover};
  }
`;

const primaryColor = '#199A57';
Button.defaultProps = {
  theme: {
    amountButton: {
      padding: '1px 6px',
      color: primaryColor,
      fontFamily: '"Maax Bold", "Arial", "sans-serif"',
      fontSize: '18px',
      width: '94px',
      height: '78px',
      border: '1px solid #C4C4C4',
      borderRadius: '4px',
      backgroundColor: '#fff',
      backgroundColorActive: '#F3FAF6',
      borderHover: `1px solid ${primaryColor}`,
      boxShadowHover: `inset 0px 0px 0px 1px ${primaryColor}`
    }
  }
};

const Ribbon = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 20%;
  background-color: ${props => props.theme.amountButton.color};
  font-family: "Maax Bold", "Arial", "sans-serif";
  color: #F3FAF6;
  font-size: 10px;
  line-height: 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export default class AmountButton extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    ribbon: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
    isChecked: PropTypes.bool,
    setValue: PropTypes.func,
    validate: PropTypes.func
  };

  constructor (props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick (e) {
    e.stopPropagation();
    e.preventDefault();

    this.props.setValue(this.props.value);
    if (this.props.validate) {
      this.props.validate();
    }
  }

  render () {
    return (
      <Button
        type='button'
        key={this.props.value}
        tabIndex='0'
        onClick={this.onClick}
        title={this.props.title}
        className={this.props.isChecked ? 'isChecked' : null}
        isChecked={this.props.isChecked}>
        {this.props.icon && <i className={`fa ${this.props.icon}`} style={{marginRight: '4px'}}/>}
        {this.props.label}
        {this.props.ribbon ? <Ribbon>{this.props.ribbon}</Ribbon> : ''}
      </Button>
    );
  }
}
