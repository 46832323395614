import React from 'react';
import {withRouter} from 'react-router-dom';
import LayoutContainer from '../../components/LayoutContainer';
import Box from '../../components/Box';
import MainContainer from '../../components/MainContainer';
import Logo from '../../components/Logo';
import SocialShareButton from '../../components/SocialShareButton';
import _ from 'lodash';
import {Field, Form} from '@nexios/frontend-forms';
import {connect} from 'react-redux';
import parseText from '../../utils/parseText';
import {Helmet} from 'react-helmet';
import useScrollToTop from '../../hooks/useScrollToTop';

const TaxDeductible4 = ({formContext, theme}) => {
  useScrollToTop();
  const socialMediaTitle = encodeURIComponent(formContext.socialMediaTitle);
  const url = encodeURIComponent(document.querySelector('meta[property=\'og:url\']').getAttribute('content'));
  const facebookUrl = `https://www.facebook.com/sharer.php?u=${url}&t=${socialMediaTitle}`;
  const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
  let twitterUrl;

  if (formContext.twitterTitle) {
    const twitterTitle = encodeURIComponent(formContext.twitterTitle);
    twitterUrl = `https://twitter.com/share?text=${twitterTitle}&url=${url}`;
  } else {
    twitterUrl = `https://twitter.com/share?text=${socialMediaTitle}&url=${url}`;
  }
  const formConfig = {
    onSubmit: () => {
    },
    onDataChanged: () => {
    },
    onInit: (ctx) => {
      const frequency = formContext.frequencies.find(f => f.value === 'yearly');
      ctx.setList('amounts', frequency.amounts.map(a => ({
        label: a.name,
        value: a.amount_m.toString()
      })));

      ctx.setValues({
        step4Header: parseText(ctx, _.get(formContext, ['step4Header'])),
        thankYouTitle: parseText(ctx, formContext.taxDeductibleThankYouTitle),
        thankYouText: parseText({...ctx, ...formContext}, formContext.taxDeductibleThankYouTextHtml_txt, theme)
      });
    },
    fields: {}
  };

  return <>
    <Helmet>
      <title>{formContext.thankYouBrowserTitle}</title>
    </Helmet>
    <MainContainer
      backgroundImageUrl={formContext.backgroundImageUrl}
      backgroundImageTabletUrl={formContext.backgroundImageTabletUrl}
      backgroundImageMobileUrl={formContext.backgroundImageMobileUrl}
    />
    <LayoutContainer
      position={formContext.textPosition}
      header={<div>
        <div style={{float: 'left', display: 'inline-block'}}>
          <Logo url={formContext.websiteUrl} />
        </div>
      </div>}
      content={<Box>
        <Form
          formInstanceKey='donate'
          formContext={formContext}
          formConfig={formConfig}>
          <Box>
            <div className='widgetContent'>
              <Field
                name='thankYouTitle'
                component={({value}) => (
                  <h1>{value}</h1>
                )} />
              <Field
                name='thankYouText'
                component={({value}) => (
                  <>
                    <div className='field'>
                      <div dangerouslySetInnerHTML={{__html: value}} />
                    </div>
                  </>
                )} />
              {formContext.allowSocialShare && <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <SocialShareButton name='facebook' url={facebookUrl} />
                <SocialShareButton name='linkedin' url={linkedinUrl} />
                <SocialShareButton name='twitter' url={twitterUrl} />
              </div>}
            </div>
          </Box>
        </Form>
      </Box>}
      footer={formContext.footer}
    />
  </>;
};

const mapStateToProps = (state) => {
  return {
    formContext: state.reducer.formContext,
    theme: state.reducer.theme
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TaxDeductible4));

