import React, {Component} from 'react';
import _ from 'lodash';
import Validation from '../Validation';
import Label from '../../components/Label';

export default class FieldWrapper extends Component {
  static defaultProps = {
    hideValidation: false,
    hideLabel: false
  };

  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
    this.setValue = this.setValue.bind(this);

    const {hasErrors, hasWarnings, hasInfos, value} = props;

    this.state = {
      showValid: hasErrors === false && hasWarnings === false && hasInfos === false && !_.isNil(value) && value !== ''
    };
  }

  validate() {
    this.setState({
      showValid: true
    });
    this.props.validate();
  }

  setValue(val) {
    this.setState({
      showValid: false
    });
    this.props.setValue(val);
  }

  render() {
    const {
      label,
      value,
      list,
      errors,
      warnings,
      infos,
      placeholder,
      hasErrors,
      hasWarnings,
      hasInfos,
      hideLabel,
      required,
      disabled,
      width,
      inline,
      name,
      inputType,
      ...rest
    } = this.props;
    const {showValid} = this.state;
    const isValid = hasErrors === false && hasWarnings === false && hasInfos === false && !_.isNil(value) && value !== '';

    const Comp = React.createElement(this.props.inputComponent, {
      ...rest,
      value: value || '',
      setValue: this.setValue,
      validate: this.validate,
      placeholder: placeholder,
      isValid: isValid && showValid,
      hasErrors: hasErrors,
      list,
      label,
      isRequired: (_.isNil(value) || value === '') && hasErrors,
      disabled,
      width,
      inline,
      isChecked: value === true,
      name,
      inputType
    });

    return (
      <React.Fragment>
        {hideLabel === false && !_.isNil(label) &&
          <Label label={label} {...{hasErrors, hasWarnings, hasInfos}} isOptional={!required} />}
        {Comp}
        {!this.props.hideValidation && <Validation {...{errors, warnings, infos}} />}
      </React.Fragment>
    );
  }
}
