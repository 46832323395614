import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import Lead1 from './views/Lead1';
import Lead2 from './views/Lead2';
import LeadAlreadyCompleted from './views/LeadAlreadyCompleted';
import Wizard from './Wizard';

export default function LeadRoutes () {
  return (
    <Switch>
      <Route
        path='/'
        exact>
        <Wizard render={({isDonationCompleted}) => (
          isDonationCompleted ? <LeadAlreadyCompleted /> : <Lead1 progressBar='1of2' />
        )} />
      </Route>
      <Route
        path='/bedankt'
        exact>
        <Lead2 progressBar='2of2' />
      </Route>
      <Redirect
        to='/'
      />
    </Switch>
  );
}
