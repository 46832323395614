import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {withRouter} from 'react-router-dom';
import BigNumber from 'bignumber.js';
import LayoutContainer from '../../components/LayoutContainer';
import Title from '../../components/Title';
import Box from '../../components/Box';
import {Form, Field, FormValidation} from '@nexios/frontend-forms';
import Button from '../../components/Button';
import MainContainer from '../../components/MainContainer';
import Checkbox from '../../components/Checkbox';
import Logo from '../../components/Logo';
import DisplayIban from '../../components/DisplayIban';
import ProgressBar from '../../components/ProgressBar';
import parseAndGetBigNumber from '../../utils/parseAndGetBigNumber';
import ButtonContainer from '../../components/ButtonContainer';
import InputAmount from '../../components/InputAmount';
import FieldWrapper from '../../components/FieldWrapper';
import ErrorMessage from '../../components/ErrorMessage';
import {updateSession} from '../../reducer';
import {gaStep1} from '../../googleAnalytics/step1';
import Dropdown from '../../components/Dropdown';
import {Helmet} from 'react-helmet';
import TextArea from '../../components/TextArea';
import TextToHtml from '../../components/TextToHtml';
import RadioButtonContainer from '../../components/RadioButtonContainer';
import useScrollToTop from '../../hooks/useScrollToTop';
import Teaser from '../../components/Teaser';
import CollectingSideBar from '../../components/CollectingSideBar';
import SinglePeriod from '../../components/SingePeriod';
import parseText from '../../utils/parseText';

BigNumber.config({
  FORMAT: {
    decimalSeparator: ',',
    groupSeparator: '.',
    groupSize: 3
  }
});

const getDefaultPeriod = formContext => {
  const periodMapping = {
    threeMonths: '3',
    sixMonths: '6',
    nineMonths: '9',
    twelveMonths: '12'
  };
  return periodMapping[formContext.defaultPeriod];
};


const onFrequencyChange = (formContext, frequency, {setList, setValues}) => {
  let theSelectedAmount = frequency.amounts.find(a => a.amount_m === frequency.defaultAmount_m);
  const showIcon = formContext.amountDisplay === 'icon';
  setList('amounts', frequency.amounts.map(a => ({
    label: showIcon ? a.label : a.name,
    value: {
      value: a.amount_m.toString(),
      icon: showIcon ? a.icon : null,
      ribbon: a.amount_m === frequency.defaultAmount_m ? frequency.defaultRibbon : ''
    }
  })));

  if (!theSelectedAmount && frequency.amounts.length === 1) {
    theSelectedAmount = frequency.amounts[0];
  }

  const values = {
    showCustomAmount: formContext.customAmountDisplay === 'inline' && frequency.allowCustomAmount,
    allowCustomAmount: frequency.allowCustomAmount,
    amountText: theSelectedAmount ? theSelectedAmount.message : null,
    amountLabel: theSelectedAmount ? theSelectedAmount.label : null,
    donationCustomAmount_m: null,
    donationAmount_m: frequency.defaultAmount_m || null
  };

  if (formContext.mandateType === 'temporary' && frequency.value === 'monthly') {
    values.period = getDefaultPeriod(formContext);
  } else if (formContext.mandateType !== 'temporary' || frequency.value !== 'monthly') {
    values.period = null;
  }

  setValues(values);
};

const Step1 = ({formContext, save, history, gaStep1, next, progressBar}) => {
  useScrollToTop();
  const formConfig = {
    onSubmit: () => {
      gaStep1(formContext);
      save(['frequency', 'showCustomAmount', 'customAmount_m', 'amount_m', 'variableFieldAnswer', 'period', 'amountLabel', 'optInAdminCostContribution', 'adminCostContributionAmount_m', 'donationCustomAmount_m', 'donationAmount_m'], () => {
        history.push(`${next}${history.location.search}`);
      });
    },
    onDataChanged: (ctx) => {
      const hasAmountChanged = _.get(ctx, ['data', 'donationAmount_m']) !== _.get(ctx, ['previousData', 'donationAmount_m']);

      if (hasAmountChanged && _.get(ctx, ['data', 'donationAmount_m']) !== null) {
        ctx.setValues({
          donationCustomAmount_m: null
        });
      }

      const showCustomAmountNewValue = _.get(ctx, ['data', 'showCustomAmount']);
      const showCustomAmountPreviousValue = _.get(ctx, ['previousData', 'showCustomAmount']);
      const showCustomAmountChanged = showCustomAmountNewValue !== showCustomAmountPreviousValue;

      if (showCustomAmountChanged && showCustomAmountNewValue === false) {
        ctx.setValues({
          donationCustomAmount_m: null
        });
      }

      const frequency = formContext.frequencies.find(f => f.value === _.get(ctx, ['data', 'frequency']));

      if (frequency) {
        if (ctx.data.frequency !== ctx.previousData.frequency) {
          onFrequencyChange(formContext, frequency, {
            setList: ctx.setList,
            setValues: ctx.setValues
          });
        }

        const theSelectedAmount = frequency.amounts.find(a => a.amount_m === ctx.data.donationAmount_m);

        if (theSelectedAmount) {
          ctx.setValues({
            amountText: theSelectedAmount ? theSelectedAmount.message : null,
            amountLabel: theSelectedAmount ? theSelectedAmount.label : null
          });
        } else {
          ctx.setValues({
            amountText: null,
            amountLabel: null
          });
        }
      }

      //Update the final values for amount and customAmount
      if (ctx.data.optInAdminCostContribution === true && formContext.askAdminCostContribution === true && ctx.data.frequency === 'oneOff') {
        ctx.setValues({
          amount_m: !_.isNil(ctx.data.donationAmount_m) ? `${((+ctx.data.donationAmount_m) + (+ctx.data.adminCostContributionAmount_m)).toFixed(2)}` : null,
          customAmount_m: !_.isNil(ctx.data.donationCustomAmount_m) ? `${((+ctx.data.donationCustomAmount_m) + (+ctx.data.adminCostContributionAmount_m)).toFixed(2)}` : null
        });
      } else {
        ctx.setValues({
          amount_m: ctx.data.donationAmount_m,
          customAmount_m: ctx.data.donationCustomAmount_m
        });
      }

      ctx.validate(true);
    },
    onInit: (ctx) => {
      let frequency;

      ctx.setDefaultValues({optInAdminCostContribution: formContext.askAdminCostContribution === true, adminCostContributionAmount_m: formContext.adminCostContributionAmount_m});

      const customAmount = _.get(ctx, ['donationCustomAmount_m']);
      if (customAmount) {
        ctx.setValues({
          donationAmount_m: customAmount
        });
      }

      if (_.isNil(ctx.frequency)) {
        frequency = formContext.frequencies.find(f => f.value === formContext.defaultFrequency);
      } else {
        // use frequency stored in session
        frequency = formContext.frequencies.find(f => f.value === ctx.frequency);

        // set amountText based on stored amount in session
        const amounts = _.get(frequency, ['amounts'], []);
        const theSelectedAmount = amounts.find(a => a.amount_m === ctx.donationAmount_m);
        if (theSelectedAmount) {
          ctx.setValues({
            amountText: theSelectedAmount ? theSelectedAmount.message : null
          });
        }
      }

      if (!_.isNil(frequency)) {
        ctx.setDefaultValues({
          frequency: frequency.value
        });

        onFrequencyChange(formContext, frequency, {
          setList: ctx.setList,
          setValues: ctx.setDefaultValues
        });
      }

      ctx.setList('frequencies', formContext.frequencies);
      if (formContext.mandateType === 'temporary') {
        const periods = [];
        if (formContext.allowPeriodThreeMonths === true) {
          periods.push({label: 'Stopt automatisch na 3 maanden', value: '3'});
        }
        if (formContext.allowPeriodSixMonths === true) {
          periods.push({label: 'Stopt automatisch na 6 maanden', value: '6'});
        }
        if (formContext.allowPeriodNineMonths === true) {
          periods.push({label: 'Stopt automatisch na 9 maanden', value: '9'});
        }
        if (formContext.allowPeriodTwelveMonths === true) {
          periods.push({label: 'Stopt automatisch na 12 maanden', value: '12'});
        }
        ctx.setList('periods', periods);

      }

      if (_.isNil(ctx.period) && !_.isNil(formContext.defaultPeriod)) {
        ctx.setDefaultValues({
          period: getDefaultPeriod(formContext)
        });
      }

      const customAmountDisplay = _.get(formContext, ['customAmountDisplay']);
      let showInline = customAmountDisplay === 'inline' && frequency.allowCustomAmount;
      let showBecauseShowCustomAmount = ctx.showCustomAmount;

      if (showInline || showBecauseShowCustomAmount) {
        ctx.setValues({
          showCustomAmount: true
        });
      } else {
        ctx.setValues({
          showCustomAmount: false
        });
      }
    },
    fields: {
      allowCustomAmount: {
        isVisible: ctx => ctx.allowCustomAmount === true && formContext.customAmountDisplay === 'toggle'
      },
      donationCustomAmount_m: {
        isVisible: (ctx) => ctx.showCustomAmount === true,
        rules: [
          {
            isValid: ctx => {
              if (!ctx.showCustomAmount) {
                return true;
              }

              const amount = _.get(ctx, ['donationAmount_m']);
              const amountHasValue = amount !== '' && !_.isNil(amount);

              if (amountHasValue && formContext.customAmountDisplay === 'inline') {
                return true;
              }

              const frequency = formContext.frequencies.find(f => f.value === _.get(ctx, ['frequency']));

              const maxAmount = BigNumber(frequency.maxAmount_m || '100000');
              const minAmount = BigNumber(frequency.minAmount_m || '1');

              const amountBn = parseAndGetBigNumber(ctx.customAmount_m);

              if (_.isNil(amountBn) || amountBn.isNaN()) {
                return true;
              }

              return amountBn.isGreaterThanOrEqualTo(minAmount) && amountBn.isLessThanOrEqualTo(maxAmount);
            },
            message: ctx => {
              const frequency = formContext.frequencies.find(f => f.value === _.get(ctx, ['frequency']));

              const maxAmount = BigNumber(frequency.maxAmount_m || '100000');
              const minAmount = BigNumber(frequency.minAmount_m || '1');

              return `Vul een bedrag in tussen € ${minAmount.toFormat(2)} en € ${maxAmount.toFormat(2)}`;
            }
          },
          {
            isValid: ctx => {
              if (!ctx.showCustomAmount) {
                return true;
              }

              const amount = _.get(ctx, ['donationAmount_m']);
              const amountHasValue = amount !== '' && !_.isNil(amount);

              if (amountHasValue && formContext.customAmountDisplay === 'inline') {
                return true;
              }

              const amountBn = parseAndGetBigNumber(ctx.customAmount_m);
              return !_.isNil(amountBn) && !amountBn.isNaN();
            }
          }
        ]
      },
      frequency: {
        isVisible: () => true,
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.frequency)
          }
        ]
      },
      period: {
        isVisible: (ctx) => formContext.mandateType === 'temporary' && ctx.frequency === 'monthly',
        rules: [
          {
            isValid: ctx => {
              if (formContext.mandateType === 'temporary') {
                return _.includes(['3', '6', '9', '12'], ctx.period);
              }
              return true;
            }
          }
        ]
      },
      amountText: {
        isVisible: (ctx) => ctx.showCustomAmount !== true
      },
      donationAmount_m: {
        isVisible: (ctx) => {
          return formContext.customAmountDisplay === 'toggle' ? ctx.showCustomAmount !== true : true;
        },
        rules: [
          {
            isValid: ctx => {
              const amountHasValue = !_.isNil(ctx.donationAmount_m) && ctx.donationAmount_m !== '';
              const customAmountHasValue = !_.isNil(ctx.donationCustomAmount_m) && ctx.donationCustomAmount_m !== '';

              return amountHasValue || customAmountHasValue;
            }
          }
        ]
      },
      variableFieldAnswer: {
        isVisible: ctx => ctx.isVariableFieldVisible && ctx.donationPageType === '10_donation',
        isRequired: ctx => ctx.isVariableFieldRequired,
        rules: [
          {
            isValid: ctx => {
              if (ctx.isVariableFieldRequired) {
                return !_.isNil(ctx.variableFieldAnswer) && _.trim(ctx.variableFieldAnswer) !== '';
              }

              return true;
            }
          }
        ]
      },
      optInAdminCostContribution: {
        isVisible: ctx => ctx.frequency === 'oneOff' && formContext.askAdminCostContribution === true
      }
    }
  };

  return <>
    <Helmet>
      <title>{formContext.amountBrowserTitle}</title>
    </Helmet>
    <MainContainer
      backgroundImageUrl={formContext.backgroundImageUrl}
      backgroundImageTabletUrl={formContext.backgroundImageTabletUrl}
      backgroundImageMobileUrl={formContext.backgroundImageMobileUrl}
    />
    <LayoutContainer
      isCollecting={formContext.isCollecting}
      position={formContext.textPosition}
      header={<div>
        <div style={{float: 'left', display: 'inline-block'}}>
          <Logo url={formContext.websiteUrl} />
        </div>
        <DisplayIban iban='NL08 INGB 0000 0005 55' />
      </div>}
      content={<Box>
        <Title title={formContext.step1Header}><ProgressBar progress={progressBar} /></Title>
        <Form
          formInstanceKey='donate'
          formContext={formContext}
          formConfig={formConfig}>
          <FormValidation
            component={ErrorMessage}
            errorMessage='Je hebt nog niet alle velden ingevuld.' />
          <div className='widgetContent'>
            <TextToHtml text={formContext.step1BodyText} />
            <Field
              list='frequencies'
              name='frequency'
              component={(props) => {
                const list = _.get(props, ['list']);
                // do not render options if there is only 1 frequency
                if (_.size(list) < 2 && formContext.frequencyDisplay === 'dropdown') {
                  return null;
                }
                return <div className='marginBottom'>
                  {formContext.frequencyDisplay === 'dropdown' && <Dropdown {...props} />}
                  {formContext.frequencyDisplay === 'radiobutton' && <RadioButtonContainer {...props} />}
                </div>;
              }}
            />

            <Field
              list='amounts'
              name='donationAmount_m'
              component={(props) => {
                const list = props.list.map(a => {
                  a.ribbon = a.value.ribbon;
                  a.icon = a.value.icon;
                  a.value = a.value.value;
                  return a;
                });

                return <div className='marginBottom amountButtons'>
                  <ButtonContainer
                    fieldName='donationAmount_m'
                    valueName='donationAmount_m'
                    list={list} /></div>;
              }}
            />

            {formContext.customAmountDisplay !== 'inline' && <Field
              name='allowCustomAmount'
              component={() => <div className='marginBottom centered'>
                <Field
                  name='showCustomAmount'
                  component={({setValue, value}) => value ? null :
                    <button type='button' className='link' onClick={() => {
                      setValue(true);
                    }}>
                      Of kies een ander bedrag
                    </button>}
                />
              </div>}
            />}

            <div className='marginBottom'>
              <Field
                component={({value}) => <div className='center'>{value}</div>}
                name='amountText'
              />
            </div>

            <div className='marginBottom'>
              {formContext.customAmountDisplay !== 'inline' && <Field
                name='donationCustomAmount_m'
                component={() => <div>Vul het bedrag in dat je wilt doneren</div>}
              />}
              <Field
                name='donationCustomAmount_m'
                placeholder={formContext.customAmountDisplay === 'inline' ? 'Of vul hier een ander bedrag in' : '0,00'}
                component={FieldWrapper}
                onFocus={(e, updateField) => updateField('donationAmount_m', null)}
                inputComponent={InputAmount}
              />
            </div>

            <Field
              list='periods'
              component={(props) => {
                const list = _.get(props, ['list']);
                // do not render options if there is only 1 frequency
                if (_.size(list) === 0) {
                  return null;
                }
                if (_.size(list) === 1) {
                  return <SinglePeriod label={list[0].label} />;
                }
                return <div className='marginBottom'>
                  {formContext.periodDisplay === 'dropdown' && <Dropdown {...props} placeholder='Selecteer een periode...' />}
                  {formContext.periodDisplay === 'radiobutton' && <RadioButtonContainer {...props} />}
                </div>;
              }}
              name='period'
            />

            <div className='marginBottom'>
              <Field
                label={formContext.variableFieldLabel}
                name='variableFieldAnswer'
                inputComponent={TextArea}
                component={FieldWrapper}
                placeholder={formContext.variableFieldPlaceholder}
              />
            </div>

            <div className='marginBottom'>
              <Field
                label={parseText(formContext, formContext.adminCostContributionText)}
                name='optInAdminCostContribution'
                component={FieldWrapper}
                inputComponent={Checkbox}
                hideLabel
              />
            </div>

            <div className='marginBottom'>
              <FormValidation
                type='submit'
                label={formContext.amountCallToActionLabel || 'Doneer'}
                component={Button}
              />
            </div>

            {
              formContext.customAmountDisplay !== 'inline' && <Field
                name='showCustomAmount'
                component={({setValue, value}) => value ?
                  <button type='button' className='link' onClick={() => setValue(false)}>Terug</button> : null}
              />
            }
          </div>
        </Form>
      </Box>}
      sidebar={
        formContext.isCollecting ? <CollectingSideBar><Teaser header={formContext.pageTeaserHeader} body={formContext.pageTeaserBodyText} /></CollectingSideBar> :
          <Teaser header={formContext.pageTeaserHeader} body={formContext.pageTeaserBodyText} />
      }
      faq={
        formContext.frequentlyAskedQuestions
      }
      footer={
        formContext.footer
      }
    />
  </>
    ;
};

const mapStateToProps = (state) => {
  return {
    formContext: state.reducer.formContext
  };
};

const mapDispatchToProps = (dispatch) => ({
  save: (fields, callback) => {
    dispatch(updateSession(fields, {
      isStep1Completed: true
    }, callback));
  },
  gaStep1: (formContext) => {
    dispatch(gaStep1(formContext));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Step1));
