import React, {useState, useEffect} from 'react';
import parseAndGetBigNumber from '../../utils/parseAndGetBigNumber';
import BigNumber from 'bignumber.js';
import {Container, InputComponent} from '../Input';
import styled from 'styled-components';

const Wrapper = styled(Container)`
  > .euro-sign {
    display: ${props => props.hasValue ? 'inline' : 'none'};
  }

  :focus-within > .euro-sign {
    display: inline
  }
`;

export default function InputAmount({
                                      placeholder, setValue, validate, value, isValid, hasErrors, isRequired, updateField, onFocus = () => {
  }, ...rest
                                    }) {
  const [displayValue, setDisplayValue] = useState(value ? (parseAndGetBigNumber(value) || new BigNumber(0)).toFormat(2) : '');

  function onChange(e) {
    setDisplayValue(e.target.value);
  }

  function onBlur(e) {
    const val = parseAndGetBigNumber(e.target.value) || new BigNumber(0);
    setDisplayValue(val.toFormat(2));
    setValue(val.toFixed(2));
    validate();
  }

  useEffect(() => {
    if (value === '') {
      setDisplayValue('');
    }
  }, [value]);

  return (
    <Wrapper isValid={isValid} hasErrors={hasErrors} isRequired={isRequired} hasValue={displayValue !== ''}>
      <span className='euro-sign' style={{marginRight: '5px'}}>€</span>
      <InputComponent
        isValid={isValid}
        hasErrors={hasErrors}
        isRequired={isRequired}
        value={displayValue}
        maxLength='1000'
        onChange={onChange}
        onBlur={onBlur}
        onFocus={e => onFocus(e, updateField)}
        type='tel'
        placeholder={placeholder} />
    </Wrapper>
  );
}
