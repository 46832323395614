import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import LayoutContainer from '../../components/LayoutContainer';
import Box from '../../components/Box';
import Button from '../../components/Button';
import Teaser from '../../components/Teaser';
import MainContainer from '../../components/MainContainer';
import Logo from '../../components/Logo';
import DisplayIban from '../../components/DisplayIban';
import {destroySession} from '../../reducer';
import Title from '../../components/Title';
import useScrollToTop from '../../hooks/useScrollToTop';

const DonationAlreadyCompleted = ({formContext, startNewDonation, history, isProductForm}) => {
  useScrollToTop();
  const donationType = isProductForm ? 'bestelling' : 'donatie';
  return <>
    <MainContainer
      backgroundImageUrl={formContext.backgroundImageUrl}
      backgroundImageTabletUrl={formContext.backgroundImageTabletUrl}
      backgroundImageMobileUrl={formContext.backgroundImageMobileUrl}
    />
    <LayoutContainer
      position={formContext.textPosition}
      header={<div>
        <div style={{float: 'left', display: 'inline-block'}}>
          <Logo url={formContext.websiteUrl} />
        </div>
        <DisplayIban iban='NL08 INGB 0000 0005 55' />
      </div>}
      content={<><Box>
        <Title title={formContext.step1Header} />
        <div className='widgetContent invalidFlow'>
          <h1>Bedankt voor je {donationType}</h1>
          <p>Je {donationType} is succesvol afgerond. Middels onderstaande knop kan je een nieuwe {donationType} doen.
          </p>
          <Button label={`Start nieuwe ${donationType}`} onClick={() => {
            startNewDonation(history);
          }} />
        </div>
      </Box>
        {formContext.allowCallToAction &&
          <div dangerouslySetInnerHTML={{__html: formContext.callToAction}} />}
      </>}
      sidebar={<Teaser header={formContext.pageTeaserHeader} body={formContext.pageTeaserBodyText} />}
      footer={formContext.footer}
    />

  </>;
};

const mapStateToProps = (state) => {
  return {
    formContext: state.reducer.formContext,
    isProductForm: state.reducer.isProductForm
  };
};

const mapDispatchToProps = (dispatch) => ({
  startNewDonation: (history) => {
    dispatch(destroySession(history));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DonationAlreadyCompleted));
