import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import View from '../Lead2/View';

const Lead2 = ({formContext, theme}) => {
  return <View formContext={formContext} theme={theme} textField={'petitionThankYouTextHtml_txt'} titleField={'petitionThankYouTitle'} />;
};

const mapStateToProps = (state) => {
  return {
    formContext: state.reducer.formContext,
    theme: state.reducer.theme
  };
};

export default connect(mapStateToProps)(withRouter(Lead2));

