import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import Petition1 from './views/Petition1';
import Petition2 from './views/Petition2';
import PetitionAlreadyCompleted from './views/PetitionAlreadyCompleted';
import Wizard from './Wizard';

export default function LeadRoutes () {
  return (
    <Switch>
      <Route
        path='/'
        exact>
        <Wizard render={({isDonationCompleted}) => (
          isDonationCompleted ? <PetitionAlreadyCompleted /> : <Petition1 progressBar='1of2' />
        )} />
      </Route>
      <Route
        path='/bedankt'
        exact>
        <Petition2 progressBar='2of2' />
      </Route>
      <Redirect
        to='/'
      />
    </Switch>
  );
}
