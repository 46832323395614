import {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

function SendTestInformation({isTesting, sendTestInfo, location}) {
  useEffect(() => {
    if (!isTesting) {
      return;
    }

    sendTestInfo({
      step: location.pathname,
      search: location.search
    });
  }, [
    location.pathname,
    isTesting,
    location.search,
    sendTestInfo
  ]);

  return null;
}

const WrappedTestInformation = connect(state => {
  const isTesting = state.reducer.formContext.isTesting;

  return {
    isTesting
  };
}, dispatch => {
  return {
    sendTestInfo: (data) => {
      dispatch((d, getState) => {
        const state = getState();

        const formState = state.form;

        const keys = Object.keys(formState).filter(key => key.startsWith('donate_data'));

        const formData = keys.reduce((result, key) => {
          const val = state.form[key];

          const attr = key.substring('donate_data_'.length);

          result[attr] = val;
          return result;
        }, {});

        d({
          type: 'splitTest.send',
          body: {
            ...data,
            donationPageVariantKey: state.reducer.formContext.pageVariantKey,
            formData
          }
        });
      });
    }
  };
})
(withRouter(SendTestInformation));

export default WrappedTestInformation;
