import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

const hasError = css`
  color: ${props => props.theme?.label?.colorError};
`;

const Lbl = styled.label`
  color: ${props => props.theme?.label?.color};
  font-size: ${props => props.theme?.label?.fontSize};
  ${props => props.hasError ? hasError : null}
`;

const Optional = styled.span`
  color: ${props => props.theme?.optionalLabel?.color};
  display: ${props => props.theme?.optionalLabel?.display || 'inline'};
`;

Optional.defaultProps = {
  theme: {
    optionalLabel: {
      color: '#818181',
      display: 'inline'
    }
  }
};

const Required = styled.span`
  color: ${props => props.theme?.requiredLabel?.color};
  display: ${props => props.theme?.requiredLabel?.display || 'none'};
`;

Required.defaultProps = {
  theme: {
    requiredLabel: {
      color: '#c00404',
      display: 'none'
    }
  }
};

Lbl.defaultProps = {
  theme: {
    label: {
      fontSize: '16px',
      color: '#000',
      colorError: '#E8141E'
    }
  }
};

export default class Label extends Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string,PropTypes.object]).isRequired,
    hasError: PropTypes.bool,
    isOptional: PropTypes.bool
  };

  render () {
    return (
      <div>
        <Lbl hasError={this.props.hasError}>
          {this.props.label}
          {this.props.isOptional && <Optional>&nbsp;(optioneel)</Optional>}
          {!this.props.isOptional &&<Required>&nbsp;*</Required>}
      </Lbl>
      </div>
    );
  }
}
