import {getDonationProduct, isProductForm, getProducts, hasOnTopAmount, getFormData} from '../helpers';

export function gaStep1 (formContext) {
  return (dispatch, getState) => {
    const state = getState();
    const data = getFormData(state);

    if (isProductForm(formContext)) {
      window.dataLayer.push({
        'event': 'checkout',
        'ecommerce': {
          'checkout': {
            'actionField': {
              'step': 1,
              'option': hasOnTopAmount(data) ? 'extra gift' : 'geen extra gift'
            },
            products: getProducts(formContext, state)
          }
        }
      });
    } else {
      window.dataLayer.push({
        'ecommerce': {
          'checkout': {
            'actionField': {'step': 1},
            'products': getDonationProduct(formContext, state)
          }
        },
        'event': 'ecomCheckout'
      });
    }
  };
}
