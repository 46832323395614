import {getFormData, getDonationProduct, isProductForm, hasOnTopAmount, getProducts} from '../helpers';
import _ from 'lodash';

export function gaStep2 (formContext) {
  return (dispatch, getState) => {
    const state = getState();
    const data = getFormData(state);

    const hasNewsLetter = formContext.allowNewsletterDisplay === 'step2'

    if (isProductForm(formContext)) {
      window.dataLayer.push({
        'event': 'checkout',
        'newsletterSubscription': hasNewsLetter ? !!_.get(data, ['allowNewsletter']) : undefined,
        'ecommerce': {
          'checkout': {
            'actionField': {
              'step': 2,
              'option': hasOnTopAmount(data) ? 'extra gift' : 'geen extra gift'
            },
            products: getProducts(formContext, state)
          }
        }
      });
    } else {
      window.dataLayer.push({
        'event': 'ecomCheckout',
        'newsletterSubscription': hasNewsLetter ? !!_.get(data, ['allowNewsletter']) : undefined,
        'ecommerce': {
          'checkout': {
            'actionField': {'step': 2, 'option': data.donateAsOrganisation ? 'organisatie' : 'persoon'},
            'products': getDonationProduct(formContext, state)
          }
        }
      });
    }
  };
}
