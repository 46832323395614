import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  padding: 7px 32px;
  background-color: black;
  display: ${props => props.theme.ibanContainer.display};
  margin-left: 34px;
  font-size: 24px;
  
  @media (max-width: 560px) {
      padding: 0 20px;
      font-size: 14px;
      margin-left: 54px;
    }
`;

const Iban = styled.span`
  font-family: ${props => props.theme.iban.fontFamily};
  color: ${props => props.theme.iban.color};
`;

Iban.defaultProps = {
  theme: {
    iban: {
      fontFamily: '"Maax Bold", "Arial", "sans-serif"',
      color: '#fff'
    }
  }
};

Container.defaultProps = {
  theme: {
    ibanContainer: {
      display: 'inline-block'
    }
  }
}

export default class DisplayIban extends Component {
  static propTypes = {
    iban: PropTypes.string.isRequired
  };

  render () {
    return (
      <Container>
        <Iban>{this.props.iban}</Iban>
      </Container>
    );
  }
}
