import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import LayoutContainer from '../../components/LayoutContainer';
import Box from '../../components/Box';
import Button from '../../components/Button';
import MainContainer from '../../components/MainContainer';
import Logo from '../../components/Logo';
import {destroySession} from '../../reducer';
import Title from '../../components/Title';
import useScrollToTop from '../../hooks/useScrollToTop';
import Teaser from '../../components/Teaser';

const DonationAlreadyCompleted = ({formContext, startNewDonation, history}) => {
  useScrollToTop();
  return <>
    <MainContainer
      backgroundImageUrl={formContext.backgroundImageUrl}
      backgroundImageTabletUrl={formContext.backgroundImageTabletUrl}
      backgroundImageMobileUrl={formContext.backgroundImageMobileUrl}
    />
    <LayoutContainer
      position={formContext.textPosition}
      header={<div>
        <div style={{float: 'left', display: 'inline-block'}}>
          <Logo url={formContext.websiteUrl} />
        </div>
      </div>}
      content={<Box>
        <Title title={formContext.step1Header} />
        <div className='widgetContent invalidFlow'>
          <p>Bedankt, uw aanvraag is succesvol ingediend. Middels onderstaande knop kunt u een nieuwe aanvraag starten.
          </p>
          <Button label='Aanvragen' onClick={() => {
            startNewDonation(history);
          }} />
        </div>
      </Box>}
      sidebar={<Teaser header={formContext.pageTeaserHeader} body={formContext.pageTeaserBodyText} />}
      footer={formContext.footer}
    />

  </>;
};

const mapStateToProps = (state) => {
  return {
    formContext: state.reducer.formContext
  };
};

const mapDispatchToProps = (dispatch) => ({
  startNewDonation: (history) => {
    dispatch(destroySession(history));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DonationAlreadyCompleted));
