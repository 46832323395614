import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import checkmark from './checkmark.png';

const Btn = styled.div`
  width: 100%;
  user-select: none;
  height: auto;
  line-height: 56px;
  font-family: ${props => props.theme.optInButton.fontFamily};
  font-size: ${props => props.theme.optInButton.fontSize};
  font-style: normal;
  letter-spacing: 0.6px;
  text-align: center;
  color: ${props => props.theme.optInButton.color};
  border-radius: ${props => props.theme.optInButton.borderRadius};
  background-color: ${props => props.theme.optInButton.backgroundColor};
  border-bottom: ${props => props.theme.optInButton.borderBottom};
  box-shadow: ${props => props.theme.optInButton.boxShadow};
  cursor: pointer;

  &:active {
    ${props => props.theme.optInButton.active};
  }

  &:focus {
    ${props => props.theme.optInButton.focus};
  }

  &:hover {
    ${props => props.theme.optInButton.hover};
  }
`;

const Box = styled.div`
  padding: ${props => props.isVisible ? '0 16px 16px 16px': '0 16px'};
  display: block;
  max-height: ${props => props.isVisible ? '500px' : '0'};
  overflow: hidden;
  background-color: ${props => props.theme.optInButton.backgroundColor};
  transition: max-height 0.3s linear;

  button {
    margin-top: 21px;
  }
`;

const OptInDone = styled.div`
  font-family: ${props => props.theme.optInButton.fontFamily};
  font-size: 31px;
  color: #199A57;

  .checkmark {
    background: url(${checkmark}) no-repeat 0;
    width: 35px;
    height: 20px;
    display: inline-block;
  }
`;

const primary = '#F7CC26';
Btn.defaultProps = {
  theme: {
    optInButton: {
      fontFamily: 'Maax Bold',
      fontSize: '20px',
      height: '56px',
      backgroundColor: '#000',
      color: primary,
      boxShadow: 'none',
      active: css`
        background-color: #000;
        border-bottom: none;
        box-shadow: none !important;
      `,
      hover: css`
        background-color: #000;
      `,
      focus: css`
        box-shadow: none;
      `
    }
  }
};

function OptIn({label, isOptInDone, children}) {
  const [isBoxVisible, setIsBoxVisible] = React.useState(false);


  if (isOptInDone) {
    return <OptInDone>
      <span className='checkmark' />Aangemeld
    </OptInDone>;
  }

  return (
    <>
      <Btn
        onClick={() => {
          setIsBoxVisible(!isBoxVisible);
        }}>
        {label}
      </Btn>
      <Box isVisible={isBoxVisible}>
        {children}
      </Box>
    </>
  );
}

OptIn.propTypes = {
  label: PropTypes.string.isRequired,
  isOptInDone: PropTypes.bool
};

export default OptIn;
