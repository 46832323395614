import React, {Component} from 'react';
import moment from 'moment';
import _ from 'lodash';
import styled, {css} from 'styled-components';
import correct from '../../static/images/icons/correct.svg';
import error from '../../static/images/icons/error.svg';

const hasErrors = css`
  position: relative;
  border: ${props => props.theme.dateDropdown.borderFocus || '1px solid transparent'};
  box-shadow: ${props => props.theme.dateDropdown.boxShadowError};
  background: url(${error})  no-repeat scroll calc(100% - 3px) 3px;
  background-color: rgba(220, 40, 30, 0.1);
  color: #E8141E;

  &:focus {
    border: 1px solid transparent;
    box-shadow: 0 0 0 3px #c4c4c4;
  }
`;

const isValid = css`
  position: relative;
  border: ${props => props.theme.dateDropdown.borderValid || '1px solid transparent'};
  box-shadow: ${props => props.theme.dateDropdown.boxShadowValid};
  background: url(${correct}) no-repeat scroll calc(100% - 3px) 3px;
  background-color: ${props => props.theme.dateDropdown.backgroundColorValid || 'rgba(7, 139, 98, 0.1)'};

  &:focus {
    border: 1px solid transparent;
    box-shadow: 0 0 0 3px #c4c4c4;
  }
`;

const Container = styled.div`
  display: flex;
`;

const Dropdown = styled.div`
  position:relative;
  box-sizing: border-box;

  select {
    -webkit-appearance: none;
    -webkit-box-sizing: border-box;
    -moz-appearance: none;
    height: ${props => props.theme.dateDropdown.height || '46px'};
    padding: ${props => props.theme.dateDropdown.padding};
    background: transparent;
    border-radius: ${props => props.theme.dateDropdown.borderRadius || '4px'};
    border: ${props => props.theme.dateDropdown.border};
    font-size: 16px;
    letter-spacing: 0.6px;
    color: #000;
    width: 100%;

    ${props => props.hasErrors ? hasErrors : null}
    ${props => props.isValid ? isValid : null}

    &:focus {
      border: ${props => props.theme.dateDropdown.borderFocus};
      box-shadow: ${props => props.theme.dateDropdown.boxShadowFocus};
    }
  }

  &:after {
    content: '\\f107';
    font-family: 'FontAwesome';
    font-size: 30px;
    right: 10px;
    top: ${props => props.theme.dateDropdown.chevronTop || '12px'};
    position: absolute;
    pointer-events: none;
    user-select: none;
  }

  &.day {
    flex: 1;
    margin-right: 5px;
  }

  &.month {
    flex: 1;
    margin-right: 5px;
  }

  &.year {
    flex: 1;
  }
`;

Dropdown.defaultProps = {
  theme: {
    dateDropdown: {
      padding: '15.6px 30px 12.4px 10px',
      border: '1px solid #c4c4c4',
      boxShadowFocus: '0 0 0 3px #c4c4c4',
      borderFocus: '1px solid transparent',
      boxShadowError: '0 0 0 1px #E8141E',
      boxShadowValid: '0 0 0 1px #199A57'
    }
  }
};

class DateInput extends Component {
  constructor (props) {
    super(props);
    const {value} = props;
    this.onChangeDay = this.onChangeDay.bind(this);
    this.onChangeMonth = this.onChangeMonth.bind(this);
    this.onChangeYear = this.onChangeYear.bind(this);
    const currentYear = moment().year();
    const days = [
      <option
        key='day-placeholder'
        value=''>Dag</option>,
      ..._.range(1, 32).map(value => <option
        value={value}
        key={`day-${value}`}>{value}</option>)
    ];
    const months = [
      <option
        value=''
        key='month-placeholder'>Maand</option>,
      <option
        value='0'
        key='month-0'>januari</option>,
      <option
        value='1'
        key='month-1'>februari</option>,
      <option
        value='2'
        key='month-2'>maart</option>,
      <option
        value='3'
        key='month-3'>april</option>,
      <option
        value='4'
        key='month-4'>mei</option>,
      <option
        value='5'
        key='month-5'>juni</option>,
      <option
        value='6'
        key='month-6'>juli</option>,
      <option
        value='7'
        key='month-7'>augustus</option>,
      <option
        value='8'
        key='month-8'>september</option>,
      <option
        value='9'
        key='month-9'>oktober</option>,
      <option
        value='10'
        key='month-10'>november</option>,
      <option
        value='11'
        key='month-11'>december</option>
    ];
    const years = [
      <option
        value=''
        key='year-placeholder'>Jaar</option>,
      ..._.range(currentYear - 18, currentYear - 120).map(value => <option
        value={value}
        key={`year-${value}`}>{value}</option>)
    ];

    this.state = {
      days,
      months,
      years,
      day: value === '' ? '' : moment(value, 'YYYY-MM-DD').date() + '',
      month: value === '' ? '' : moment(value, 'YYYY-MM-DD').month() + '',
      year: value === '' ? '' : moment(value, 'YYYY-MM-DD').year() + ''
    };
  }

  componentDidUpdate (prevProps) {
    if (this.props.value !== prevProps.value) {
      const {value} = this.props;
      this.setState({
        day: value === '' ? '' : moment(value, 'YYYY-MM-DD').date() + '',
        month: value === '' ? '' : moment(value, 'YYYY-MM-DD').month() + '',
        year: value === '' ? '' : moment(value, 'YYYY-MM-DD').year() + ''
      });
    }
  }

  onChangeDay (e) {
    const day = e.target.value;
    this.setState({
      day
    }, this.setValue);
  }

  onChangeMonth (e) {
    const month = e.target.value;
    this.setState({
      month
    }, this.setValue);
  }

  onChangeYear (e) {
    const year = e.target.value;
    this.setState({
      year
    }, this.setValue);
  }

  setValue () {
    const {day, month, year} = this.state;
    const date = moment()
      .year(_.toInteger(year))
      .month(_.toInteger(month))
      .date(_.toInteger(day));

    const hasValue = value => !_.isNil(value) && value !== '';
    if (hasValue(year) && hasValue(month) && hasValue(day)) {
      this.props.setValue(date.format('YYYY-MM-DD'));
      this.props.validate();
    } else {
      this.props.setValue(null);
    }
  }

  render () {
    const {days, months, years, day, month, year} = this.state;
    const {hasErrors, isValid} = this.props;
    return (
      <Container>
        <Dropdown className='day' hasErrors={hasErrors} isValid={isValid}>
          <select
            name={`${this.props.name}-day`}
            onChange={this.onChangeDay}
            value={day}>
            {days}
          </select>
        </Dropdown>
        <Dropdown className='month' hasErrors={hasErrors} isValid={isValid}>
          <select
            name={`${this.props.name}-month`}
            onChange={this.onChangeMonth}
            value={month}>
            {months}
          </select>
        </Dropdown>
        <Dropdown className='year' hasErrors={hasErrors} isValid={isValid}>
          <select
            name={`${this.props.name}-year`}
            onChange={this.onChangeYear}
            value={year}>
            {years}
          </select>
        </Dropdown>
      </Container>
    );
  }
}

export default DateInput;
