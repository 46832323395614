import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import ideal from './icons/ideal.svg';
import paypal from './icons/paypal.svg';
import creditcard from './icons/creditcard.svg';
import directDebit from './icons/directDebit.png';
import manualTransfer from './icons/manualTransfer.svg';

const isChecked = css`
  border: ${props => props.theme.paymentButton.borderHover};
  box-shadow: ${props => props.theme.paymentButton.boxShadowHover};
  background-color: ${props => props.theme.paymentButton.backgroundColorActive};
`;

const Button = styled.button`
  padding-top: 22px;
  font-family: ${props => props.theme.paymentButton.fontFamily};
  font-weight: ${props => props.theme.paymentButton.fontWeight};
  font-size: ${props => props.theme.paymentButton.fontSize};
  width: ${props => props.theme.paymentButton.width};
  height: ${props => props.theme.paymentButton.height};
  line-height: ${props => props.theme.paymentButton.lineHeight};
  box-sizing: border-box;
  text-align: center;
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  border: ${props => props.theme.paymentButton.border};
  border-radius: ${props => props.theme.paymentButton.borderRadius};
  background-color: ${props => props.theme.paymentButton.backgroundColor};

  background-repeat: no-repeat;
  background-position: 50% 35%;
  background-size: auto 27px;

  ${props => props.value === 'ideal' ? css`background-image: url(${ideal});` : null}
  ${props => props.value === 'payPal' ? css`background-image: url(${paypal});` : null}
  ${props => props.value === 'creditCard' ? css`background-image: url(${creditcard});` : null}
  ${props => props.value === 'directDebit' ? css`background-image: url(${directDebit});` : null}
  ${props => props.value === 'manualTransfer' ? css`background-image: url(${manualTransfer});` : null}

  color: ${props => props.isChecked ? props.theme.paymentButton.color : '#000'};

  ${props => props.isChecked ? isChecked : null}
  &:hover {
    border: ${props => props.theme.paymentButton.borderHover};
    box-shadow: ${props => props.theme.paymentButton.boxShadowHover};
  }

  &:focus {
    border: ${props => props.theme.paymentButton.borderHover};
    box-shadow: ${props => props.theme.paymentButton.boxShadowHover};
  }
`;

const primaryColor = '#199A57';
Button.defaultProps = {
  theme: {
    paymentButton: {
      color: primaryColor,
      fontFamily: '"Maax Regular", "Arial", "sans-serif"',
      fontSize: '16px',
      width: '94px',
      height: '78px',
      lineHeight: '78px',
      border: '1px solid #C4C4C4',
      borderRadius: '4px',
      backgroundColor: '#fff',
      backgroundColorActive: '#F3FAF6',
      borderHover: `1px solid ${primaryColor}`,
      boxShadowHover: `inset 0px 0px 0px 1px ${primaryColor}`
    }
  }
};

export default class PaymentMethodButton extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isChecked: PropTypes.bool,
    setValue: PropTypes.func,
    validate: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    e.stopPropagation();
    e.preventDefault();

    this.props.setValue(e.target.value);
    if (this.props.validate) {
      this.props.validate();
    }
  }

  onChange(e) {
    this.props.setValue(e.target.value);

    if (this.props.validate) {
      this.props.validate();
    }
  }

  render() {
    return (
      <Button
        type='button'
        key={this.props.value}
        tabIndex='0'
        onClick={this.onClick}
        value={this.props.value}
        isChecked={this.props.isChecked}>
        {this.props.label}
        <div
          value={this.props.value}
          onChange={this.onChange}
        />
      </Button>
    );
  }
}
